'use client';

import { type ConfigureDietReturnFormType } from '../../forms/configure-diet';
import { Progress } from '@/components/ui/progress';
import { type DietCatalogDetail } from '@/features/diets/types';
import { useTranslations } from 'next-intl';
import React, { useEffect, useMemo, useState } from 'react';

export const MealsBar = ({
  diet,
  form,
}: {
  diet: DietCatalogDetail;
  form: ConfigureDietReturnFormType;
}) => {
  const t = useTranslations();
  const kcal = form.getValues('kcal');
  const meals = form.getValues('meals');
  const currentCalorifics = useMemo(
    () => diet.calorifics.find((c) => c.calories === Number(kcal)),
    [kcal],
  );
  const [percentage, setPercentage] = useState(0);
  const [selectedCalorifics, setSelectedCalorifics] = useState(0);

  const getPercentage = () => {
    const selectedMealsKcal = currentCalorifics?.mealsCaloricValue.reduce(
      (acc, curr) => {
        if (meals.includes(curr.mealId)) {
          return acc + curr.caloricity;
        }
        return acc;
      },
      0,
    );
    setSelectedCalorifics(Number(selectedMealsKcal));
    return (Number(selectedMealsKcal) / Number(kcal)) * 100;
  };

  useEffect(() => {
    setPercentage(getPercentage());
  }, [meals, kcal]);

  return (
    <div className='bg-white rounded-lg px-4 py-5 mt-4 flex flex-col'>
      <header className='flex gap-1 md:gap-4 md:items-center md:mb-2 flex-col md:flex-row order-2 md:order-none'>
        <p className='flex justify-between gap-1 items-center'>
          {t('cart.configuration.meals_amount')}:<strong>{meals.length}</strong>
        </p>
        <p className='flex justify-between gap-1 items-center'>
          {t('cart.configuration.calories')}:
          <strong>{selectedCalorifics} kcal</strong>
        </p>
      </header>
      <Progress
        value={percentage}
        className='h-[5px] bg-brand-grey2 [&_>div]:bg-brand-green [&_>div]:transition-none order-1 md:order-none mb-4 md:mb-0 mt-2 md:mt-0'
      />
    </div>
  );
};
