import { type DietCatalogDiscountType } from '../types';

export const getDiscountDetails = ({
  price,
  discountCodes,
}: {
  discountCodes?: DietCatalogDiscountType[];
  price?: number;
}) => {
  if (!price || !discountCodes || discountCodes.length === 0) {
    return {
      priceAfterDiscount: undefined,
      hasDiscount: false,
    };
  }
  const biggestDiscount = discountCodes?.reduce((prev, current) =>
    prev.amount > current.amount ? prev : current,
  );

  const priceAfterDiscount = biggestDiscount
    ? biggestDiscount.unit === 'percentage'
      ? price - price * (biggestDiscount.amount / 100)
      : price - biggestDiscount.amount
    : price;

  return {
    priceAfterDiscount,
    hasDiscount: true,
  };
};
