import { zodResolver } from '@hookform/resolvers/zod';
import { type DefaultValues, useForm } from 'react-hook-form';
import { type TypeOf, type z } from 'zod';

export const useFormResolver = <
  T extends z.AnyZodObject | z.ZodEffects<z.AnyZodObject>,
>({
  schema,
  defaultValues,
}: {
  schema: T;
  defaultValues: DefaultValues<TypeOf<T>>;
}) => {
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  return form;
};
