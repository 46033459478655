import { getDiscountDetails } from '../helpers/get-discount-details';
import { type DietCatalogDiscountType } from '../types';
import { type DietPricingComplex } from '../types/diet-pricing-complex.type';
import { DiscountCodes } from './discount-codes';
import { ListingItemAttributes } from '@/components/listing/listing-item-attributes';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';

const ComplexPricing = ({
  priceComplex,
}: {
  priceComplex: DietPricingComplex;
}) => {
  const t = useTranslations();

  return (
    <div className='w-full md:mt-2'>
      {priceComplex.discountCodes && (
        <div className='flex'>
          <DiscountCodes
            discountCodes={priceComplex.discountCodes}
            codeCopy
            className='mt-2 block md:hidden'
          />
        </div>
      )}
      <div className='flex basis-full gap-2 lg:gap-0'>
        <ListingItemAttributes className='block flex-1 items-end gap-2 whitespace-nowrap md:flex xl:block'>
          <span className='bg-brand-grey1 flex items-center p-4 max-md:mt-4 max-md:flex-row-reverse max-md:justify-between max-md:rounded-lg md:flex-col md:items-start md:bg-transparent md:p-0'>
            <span className='flex gap-1 md:-mt-1'>
              <span className='text-base font-normal md:hidden'>suma: </span>
              <span className='text-base font-bold'>
                {t('common.price', {
                  price: priceComplex.totalPrice.toFixed(2),
                })}
              </span>
              {priceComplex.discountCodes && (
                <DiscountCodes
                  discountCodes={priceComplex.discountCodes}
                  codeCopy
                  className='ml-1 hidden md:block'
                />
              )}
            </span>
            <span className='md:text-sm'>
              {t('common.pricePerDay', {
                price: priceComplex.pricePerDay.toFixed(2),
              })}
            </span>
          </span>
        </ListingItemAttributes>
      </div>
    </div>
  );
};

const SimplePricing = ({
  price,
  discountCodes,
}: {
  price?: number;
  discountCodes?: DietCatalogDiscountType[];
}) => {
  const t = useTranslations();
  const { hasDiscount, priceAfterDiscount } = getDiscountDetails({
    price,
    discountCodes,
  });

  return (
    <div className='flex basis-1/2 gap-2 lg:gap-0'>
      <div className='flex basis-1/2 gap-2 lg:gap-0'>
        <ListingItemAttributes
          label={t('diets.price_from')}
          className='block flex-1 items-end gap-2 whitespace-nowrap'
        >
          <span
            className={cn('-mt-1 inline-block text-xl font-bold leading-6', {
              'text-md mt-0 font-normal line-through': hasDiscount,
            })}
          >
            {t('common.price', { price: price?.toFixed(2) })}
          </span>{' '}
          {!hasDiscount && `/ ${t('diets.day')}`}
          {priceAfterDiscount && priceAfterDiscount !== price && (
            <span className={'text-brand-red text-md font-bold'}>
              <span>
                {t('common.price', {
                  price: priceAfterDiscount.toFixed(2),
                })}
              </span>
              <span className='font-normal'> / {t('diets.day')}</span>
            </span>
          )}
        </ListingItemAttributes>
      </div>
    </div>
  );
};

const SummaryPricing = ({
  price,
  discountCodes,
}: {
  price?: number;
  discountCodes?: DietCatalogDiscountType[];
}) => {
  const t = useTranslations();
  const { hasDiscount, priceAfterDiscount } = getDiscountDetails({
    price,
    discountCodes,
  });

  return (
    <div className='flex basis-1/2 gap-2 lg:gap-0'>
      <div className='flex basis-1/2 gap-2 lg:gap-0'>
        <ListingItemAttributes
          label={t('common.summaryPrice')}
          className='block flex-1 items-end gap-2 whitespace-nowrap'
        >
          <span
            className={cn('-mt-1 inline-block text-xl font-bold leading-6', {
              'text-md mt-0 font-normal line-through': hasDiscount,
            })}
          >
            {t('common.price', { price: price?.toFixed(2) })}
          </span>{' '}
          {priceAfterDiscount && priceAfterDiscount !== price && (
            <span className={'text-brand-red text-md font-bold'}>
              <span>
                {t('common.price', {
                  price: priceAfterDiscount.toFixed(2),
                })}
              </span>
            </span>
          )}
        </ListingItemAttributes>
      </div>
    </div>
  );
};

export const DietListItemPricing = ({
  price,
  priceComplex,
  priceSummary,
  discountCodes,
}: {
  price?: number;
  priceSummary?: number;
  priceComplex?: DietPricingComplex;
  discountCodes?: DietCatalogDiscountType[];
}) => {
  if (!!priceComplex) return <ComplexPricing priceComplex={priceComplex} />;
  if (!!priceSummary) return <SummaryPricing price={priceSummary} />;

  return <SimplePricing price={price} discountCodes={discountCodes} />;
};
