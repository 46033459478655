import { type CartUpdateAction, type ItemPriceBody } from '../types';
import { type Cart } from '../types/cart.type';
import { type OrderInvoice } from '../types/invoice-data.type';
import { toast } from '@/components/ui/sonner';
import { type ContactInfoReturnType } from '@/features/checkout/forms';
import {
  type DietCatalog,
  type DietCatalogCateringType,
} from '@/features/diets/types';
import {
  getDecryptedLocalStorage,
  setEncryptedLocalStorage,
} from '@/lib/storage';
import { type PersistStorage, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

type CartState = Cart & {
  // cartId is taken from the API - it's a unique identifier for the cart
  // It's used to identify the cart in the backend
  cartId?: string;

  // if the user created an order, we should store it for updating the cart
  currentOrderId?: string;

  // pending items
  pendingItems?: ItemPriceBody[];
  pendingEditItems?: (ItemPriceBody & { id: string })[];

  // invoices
  invoiceData: OrderInvoice | null;
  invoiceActive: boolean;
  invoiceUseSameAddress?: boolean;

  // address error
  addressNotSupported?: boolean;

  // disabled items
  disabledItems?: string[];

  // delivery costs
  deliveryCosts?: {
    itemId: string;
    dietId: string;
    cost: DietCatalogCateringType['deliveryCosts'];
  }[];
};

type CartActions = {
  // cartId
  setCartId: (cartId: string) => void;
  setCart: (cart: Cart) => void;
  resetCart: () => void;

  // update cart
  updateCart: (
    cartId: string,
    cart: Cart,
    action: CartUpdateAction,
    t?: (key: string) => string,
  ) => Cart;

  // as id1,id2,id3
  getDietIds: () => string;

  getMultidietDietIds: () => string;

  setOrderId: (orderId: string) => void;
  getAddress: () => Partial<ContactInfoReturnType> | undefined;
  getInvoiceData: () => OrderInvoice | null;
  setInvoiceData: (invoiceData: OrderInvoice) => void;
  setInvoiceUseSameAddress: (useSameAddress: boolean) => void;

  // pending items actions
  addPendingItem: (item: ItemPriceBody) => void;
  addPendingEditItem: (item: ItemPriceBody, id: string) => void;

  // address error
  setAddressNotSupported: (value: boolean) => void;

  // disabled items
  setDisabledItems: (items: string[]) => void;

  addDeliveryCost: (
    itemId: string,
    dietId: string,
    cost: DietCatalogCateringType['deliveryCosts'],
  ) => void;

  removeDeliveryCost: (itemId: string) => void;
  clearDeliveryCost: () => void;
  getDeliveryCost: () => DietCatalogCateringType['deliveryCosts'] | null;
  setDeliveryCosts: (diets: DietCatalog[]) => void;
};

type CartStore = CartState & CartActions;

const defaultValues: CartState = {
  cartId: undefined,
  currentOrderId: undefined,
  pendingItems: [],
  items: [],
  multidietItems: [],
  disabledItems: [],
  pricing: {
    final: {
      gross: 0,
      net: 0,
      tax: 0,
    },
    before: {
      gross: 0,
      net: 0,
      tax: 0,
    },
    discounts: [],
    delivery: {
      gross: 0,
      net: 0,
      tax: 0,
    },
  },
  discountCode: null,
  invoiceData: null,
  invoiceActive: false,
  agreements: {
    cateringPolicy: false,
    terms: false,
    marketing: false,
    privacy: false,
  },
};

const storage: PersistStorage<CartState> = {
  getItem: (name: string) => {
    return getDecryptedLocalStorage(name);
  },
  setItem: (name: string, value: any) => {
    setEncryptedLocalStorage(name, value);
  },
  removeItem: (name: string) => {
    localStorage.removeItem(name);
  },
};

const createCartStore = ({ storageKey }: { storageKey: string }) =>
  createStore<CartStore>()(
    persist(
      (set, get) => ({
        // default state
        ...defaultValues,

        // cartId actions
        setCartId: (cartId: string) => set({ cartId }),
        setCart: (cart: Cart) => {
          return set(cart);
        },
        resetCart: () => set(defaultValues),

        // update cart actions
        updateCart: (cartId, cart, action, t) => {
          get().setCartId(cartId);
          set(cart);
          set({ pendingItems: [], pendingEditItems: [] });

          const translate = (message: string) => (t ? t(message) : message);

          // show toast message based on the action
          switch (action) {
            case 'add':
              toast.success(translate('cart.added'));
              break;
            case 'remove':
              toast.info(translate('cart.removed'));
              break;
            case 'clear':
              toast.info(translate('cart.cleared'));
              break;
            case 'update':
              toast.success(translate('cart.updated'));
              break;
            case 'addDiscount':
              toast.success(translate('cart.addedDiscount'));
              break;
            case 'addWrongDiscount':
              toast.error(translate('cart.addedWrongDiscount'));
              break;
            case 'removeDiscount':
              toast.info(translate('cart.removedDiscount'));
              break;
            default:
              break;
          }

          return get();
        },

        getDietIds: () => {
          return get()
            .items.map((item) => item.configurationItem.dietId)
            .join(',');
        },

        getMultidietDietIds: () => {
          return get()
            .multidietItems.map((item) => item.multidiet.multidietId)
            .join(',');
        },

        setOrderId: (orderId: string) => set({ currentOrderId: orderId }),

        getAddress: () => {
          const items = get().items;
          if (items.length > 0) {
            const item = items[0]?.configurationItem;
            if (item) {
              const contactStore: Partial<ContactInfoReturnType> = {
                address: item.address,
                email: item.contact?.email,
                phone: item.contact?.phoneNumber,
                firstName: item.contact?.firstName,
                lastName: item.contact?.lastName,
              };
              return contactStore;
            }
          }
        },

        getInvoiceData: () => {
          if (get().invoiceActive && get().invoiceData) {
            const data = get().invoiceData!;
            return {
              active: true,
              companyName: data.companyName,
              vatNumber: data.vatNumber,
              postCode: data.postCode,
              city: data.city,
              street: data.street,
              buildNumber: data.buildNumber,
              flatNumber: data.flatNumber,
            };
          }
          return null;
        },

        setInvoiceData: (invoiceData: OrderInvoice | null) => {
          set({ invoiceData });
        },

        setInvoiceUseSameAddress: (useSameAddress: boolean) =>
          set({ invoiceUseSameAddress: useSameAddress }),

        addPendingItem: (item: ItemPriceBody) => {
          set((state) => ({
            pendingItems: [...(state.pendingItems || []), item],
          }));
        },

        addPendingEditItem: (item: ItemPriceBody, id: string) => {
          set((state) => ({
            pendingEditItems: [
              ...(state.pendingEditItems || []),
              { ...item, id },
            ],
          }));
        },

        setAddressNotSupported: (value: boolean) =>
          set({ addressNotSupported: value }),

        setDisabledItems: (items: string[]) => set({ disabledItems: items }),

        addDeliveryCost: (itemId, dietId, cost) => {
          set((state) => ({
            deliveryCosts: [
              ...(state.deliveryCosts || []),
              { itemId, dietId, cost },
            ],
          }));
        },

        removeDeliveryCost: (itemId) => {
          set((state) => ({
            deliveryCosts: (state.deliveryCosts || []).filter(
              (item) => item.itemId !== itemId,
            ),
          }));
        },

        clearDeliveryCost: () => {
          set({ deliveryCosts: [] });
        },

        getDeliveryCost: () => {
          if (get().items.length === 0) {
            return null;
          }
          const costs = get().deliveryCosts?.map((cost) => cost.cost);
          if (!costs) {
            return null;
          }

          if (
            costs.every(
              (val) => JSON.stringify(val) === JSON.stringify(costs[0]),
            ) &&
            costs[0]
          ) {
            return costs[0];
          }

          // if there are multiple delivery costs, return { type: 'multiple', price: null }
          if (
            costs.length > 1 &&
            costs.some(
              (val) => JSON.stringify(val) !== JSON.stringify(costs[0]),
            )
          ) {
            return { type: 'multiple', price: null };
          }

          return null;
        },

        // check delivery cost integrity
        setDeliveryCosts: (diets: DietCatalog[]) => {
          const deliveryCosts = get().deliveryCosts || [];

          // check if all items are in the delivery costs
          const items = get().items;

          // if there are no items, return
          if (!items.length) return;

          const missingItems = items.filter(
            (item) =>
              !deliveryCosts.some(
                (cost) => cost.itemId === item.configurationItem.id,
              ),
          );

          // if there are no missing items, return
          if (!missingItems.length) return;

          const missingCosts = missingItems.map((item) => {
            const diet = diets.find(
              (d) => d.id === item.configurationItem.dietId,
            );
            if (!diet || !item.configurationItem.id) return null;
            return {
              itemId: item.configurationItem.id,
              dietId: diet.id,
              cost: diet.catering.deliveryCosts,
            };
          });

          missingCosts.forEach((diet) => {
            if (!diet) return;
            get().addDeliveryCost(diet.itemId, diet.dietId, diet.cost);
          });
        },
      }),
      {
        // The key to use for storing the state in local storage.
        name: storageKey,
        storage,
      },
    ),
  );

export { createCartStore, type CartStore };
