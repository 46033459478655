import {
  type ConfigureDietFormType,
  type ConfigureDietReturnFormType,
} from '../../forms/configure-diet';
import { DiscountsKV } from '../../helpers';
import { getIntlPrice } from '../../helpers/get-intl-price';
import { type CartPricing } from '../../types';
import { DeliveryCost } from '../delivery-cost';
import { type KeyValuePairProps, PriceElement } from '../pricing/price-element';
import DocIcon from '@/assets/icons/doc.svg';
import { BounceLoader } from '@/components/common/bounce-loader';
import { ButtonLoadingIcon } from '@/components/common/buttons/button-loading-icon';
import { Button } from '@/components/ui/button';
import { EventViewItem } from '@/features/analytics/components/events/event-view-item';
import {
  type DietCatalog,
  type DietCatalogDetail,
} from '@/features/diets/types';
import { useDietDates } from '@/lib/hooks/use-diet-dates';
import { TooltipWrapper } from '@repo/ui/components/tooltip-wrapper';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { uniq } from 'lodash';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

const getErrors = (form: ConfigureDietReturnFormType) => {
  const t = useTranslations();
  const errors = [];

  if (form.getValues('dates')?.length === 0 && !form.formState.errors.dates) {
    errors.push(t('cart.configuration.errors.atLeastOneDate'));
  }

  if (form.getValues('dates')?.length !== form.getValues('daysToSelect')) {
    errors.push(t('cart.configuration.errors.amountOfDays'));
  }

  if (!form.getValues('kcal') && !form.formState.errors.kcal) {
    errors.push(t('cart.configuration.errors.caloricityRequired'));
  }

  if (!form.formState.isDirty) {
    errors.push(t('cart.configuration.errors.noChanges'));
  }

  return errors;
};

export const getKcalFromMeals = (
  diet: DietCatalogDetail | DietCatalog,
  kcal: number,
  meals: string[],
) => {
  const currentCalorifics = diet.calorifics.find(
    (cal) => cal.calories === kcal,
  );

  const selectedMealsKcal = currentCalorifics?.mealsCaloricValue?.reduce(
    (acc, curr) => {
      if (meals.includes(curr.mealId)) {
        return acc + curr.caloricity;
      }
      return acc;
    },
    0,
  );
  return selectedMealsKcal;
};

export const ConfigurationPricing = ({
  form,
  diet,
  pricing,
  loading,
  pending,
  edit,
}: {
  form: ConfigureDietReturnFormType;
  diet: DietCatalogDetail;
  pricing?: CartPricing;
  loading?: boolean;
  pending?: boolean;
  edit?: boolean;
}) => {
  const t = useTranslations();
  const isValid = form.formState.isValid;
  const isDirty = form.formState.isDirty;

  const { lastPossibleOrderDay, lastPossibleOrderDayFormatted } = useDietDates({
    diet,
    saturdays: true,
    sundays: true,
  });

  // Total price from form
  const totalPrice = pricing?.final.gross || 0;

  const dailyPriceBeforeDiscount =
    pricing?.before?.gross &&
    pricing.before.gross / (form.watch('dates')?.length || 1);
  const dailyPrice = totalPrice / (form.watch('dates')?.length || 1);

  const dailyPriceText =
    dailyPriceBeforeDiscount !== dailyPrice ? (
      <span className='flex items-center gap-x-2'>
        <span className='line-through decoration-current text-muted-foreground'>
          {getIntlPrice(dailyPriceBeforeDiscount ?? 0)}
        </span>
        <span className='text-brand-green1 font-bold'>
          {getIntlPrice(dailyPrice ?? 0)}
        </span>
      </span>
    ) : (
      <span className='text-brand-green1 font-bold'>
        {getIntlPrice(dailyPrice ?? 0)}
      </span>
    );

  // Calories from form ( supervisoriant )
  const kcal =
    getKcalFromMeals(diet, Number(form.watch('kcal')), form.watch('meals')) ||
    form.watch('kcal');

  // Number of dates from form
  const daysQuantity = form.watch('dates')?.length || 0;

  // Errors that come from defaults (not visible in form.formState.errors on first render)
  const errorArray = getErrors(form);

  // Tooltip errors - concat errors from formState and errorArray + uniq (can be duplicated)
  const errors = uniq(
    Object.entries(form.formState.errors)
      .map(([_, value]) => {
        let err = '';
        if (value.message?.includes('minDays')) {
          err = t(value.message, { days: diet.catering.days.min.toString() });
        } else if (value.message?.includes('maxDays')) {
          err = t(value.message, { days: diet.catering.days.max.toString() });
        } else if (value.message?.includes('minMeals')) {
          err = t(value.message, { meals: diet.minMealTypes.toString() });
        } else if (value.message?.includes('daysAllowedToOrderInFuture')) {
          err = lastPossibleOrderDay
            ? t(value.message, {
                date: lastPossibleOrderDayFormatted,
              })
            : '';
        } else {
          err = t(value.message);
        }
        return err;
      })
      .concat(errorArray),
  );

  const discounts: KeyValuePairProps[] = DiscountsKV({
    discounts: pricing?.discounts || [],
    t,
    className: 'text-brand-green1',
  });

  const TooltipErrors = () => (
    <ul>
      {errors.map((error) => (
        <li key={error}>{error}</li>
      ))}
    </ul>
  );

  const dirtyCheck = (field: keyof ConfigureDietFormType) =>
    form.getFieldState(field).isDirty;

  const dirty =
    dirtyCheck('dates') ||
    dirtyCheck('kcal') ||
    dirtyCheck('meals') ||
    dirtyCheck('variant');

  return (
    <div className='flex flex-col gap-y-4 lg:gap-y-2'>
      <EventViewItem
        item={diet}
        configuration={{ dates: form.watch('dates'), kcal: form.watch('kcal') }}
        price={totalPrice}
        end={!(pending || !isValid || !dirty)}
        isDirty={dirty}
        loading={loading}
      />
      <div className='order-2 lg:order-none'>
        <PriceElement
          loading={loading}
          header={{
            icon: <DocIcon className='h-6 w-6' />,
            label: t('cart.configuration.summary'),
          }}
          content={{
            header: (
              <div className='bg-background flex items-center gap-x-4 rounded-lg p-2'>
                <Image
                  width={48}
                  height={48}
                  src={diet.imageUrl}
                  alt={diet.name}
                  className='h-12 w-12 rounded-lg object-cover'
                />
                <div className='flex flex-col'>
                  <span className='font-medium'>{diet.name}</span>
                  <span className='text-muted-foreground'>
                    {diet.catering.name}
                  </span>
                </div>
              </div>
            ),
            kvs: [
              {
                label: t('cart.configuration.numberOfCalories'),
                value: !kcal ? 0 : kcal,
                valueClassName: !kcal ? 'text-brand-red font-medium' : '',
                testId: 'calories-summary',
              },
              {
                label: t('cart.configuration.numberOfMeals'),
                value: form.watch('meals')?.length.toString(),
                testId: 'meals-summary',
              },
              {
                label: t('cart.configuration.daysQuantity'),
                value: daysQuantity.toString(),
                valueClassName: daysQuantity === 0 ? 'text-brand-red' : '',
                testId: 'days-quantity',
              },
              ...discounts,
            ],
          }}
          footer={{
            kvs: [
              {
                label: t('cart.configuration.pricePerDay'),
                value: loading ? (
                  <BounceLoader className='h-4' />
                ) : (
                  dailyPriceText
                ),
              },
              {
                label: t('cart.configuration.totalPrice'),
                value: loading ? (
                  <BounceLoader className='h-7' />
                ) : (
                  getIntlPrice(totalPrice ?? 0)
                ),
                className: 'font-bold',
                valueClassName: 'text-lg text-brand-violet',
              },
            ],
            other: [
              <DeliveryCost {...diet.catering.deliveryCosts} key='delivery' />,
            ],
            buttons: (
              <TooltipWrapper
                content={<TooltipErrors />}
                active={(!isValid || !isDirty) && errors.length > 0}
              >
                <Button
                  variant='secondary'
                  type='submit'
                  form='configure-diet-form'
                  className='mt-2 h-14 w-full text-brand-green1'
                  disabled={pending}
                  data-pw='add-to-cart-button'
                >
                  <div className='flex items-center gap-2.5'>
                    <span>
                      {edit
                        ? t('cart.configuration.save')
                        : t('cart.configuration.addToCart')}
                    </span>
                    {pending ? (
                      <ButtonLoadingIcon loading={pending} />
                    ) : (
                      <IconArrowNarrowRight stroke={1.25} />
                    )}
                  </div>
                </Button>
              </TooltipWrapper>
            ),
          }}
        />
      </div>
      {diet.discountCodes && diet.discountCodes.length > 0 && (
        <div className='bg-brand-red-light w-full flex flex-col rounded-lg p-4 gap-0.5 text-brand-red order-1 md:max-lg:-mt-4 lg:order-none'>
          {diet.discountCodes.map((discountCode) => {
            return (
              <div key={discountCode.id} className='flex items-center gap-x-4'>
                <span className='font-bold text-2xl'>
                  {t('diets.discountCodeOnly', {
                    amount: discountCode.amount,
                    unit: discountCode.unit,
                  })}
                </span>
                <span className='text-base font-medium'>
                  {t('diets.discountCodeUsed', { name: discountCode.name })}
                </span>
              </div>
            );
          })}
          <p className='text-sm font-normal'>
            {t('diets.discountCodesHowToUse')}
          </p>
        </div>
      )}
    </div>
  );
};
