'use client';

import { type ConfigureDietReturnFormType } from '../../forms/configure-diet';
import { CartCalendar } from '../cart-calendar';
import { FormSection } from './configure-form-section';
import { ConfigureSupervariants } from './configure-supervariants';
import { ConfigureVariants } from './configure-variants';
import { DietConfigurationCard } from './diet-card';
import { type DietCatalogDetail } from '@/features/diets/types';
import { Globals } from '@/features/globals/types/globals.type';
import { useDietDates } from '@/lib/hooks/use-diet-dates';
import { useTranslations } from 'next-intl';
import { type FieldError, type Merge } from 'react-hook-form';

export const ConfigureDietForm = ({
  form,
  diet,
  globals,
}: {
  form: ConfigureDietReturnFormType;
  diet: DietCatalogDetail;
  globals?: Globals | null;
}) => {
  const t = useTranslations();
  const { lastPossibleOrderDayFormatted } = useDietDates({
    diet,
    saturdays: true,
    sundays: true,
  });

  const ConfigureVariant = ({
    diet,
    form,
    customError,
  }: {
    diet: DietCatalogDetail;
    form: ConfigureDietReturnFormType;
    customError?: string;
  }) =>
    !!diet.variants && diet.variants.length > 0 ? (
      <ConfigureVariants diet={diet} form={form} />
    ) : (
      <ConfigureSupervariants
        diet={diet}
        form={form}
        customError={customError}
      />
    );

  const translatedError = (
    error?: Merge<FieldError, (FieldError | undefined)[]>,
  ) => {
    if (!error) return;

    let err = '';

    if (error.message?.includes('minDays')) {
      err = t(error.message, { days: diet.catering.days.min.toString() });
    } else if (error.message?.includes('maxDays')) {
      err = t(error.message, { days: diet.catering.days.max.toString() });
    } else if (error.message?.includes('minMeals')) {
      err = t(error.message, { meals: diet.minMealTypes.toString() });
    } else if (error.message?.includes('daysAllowedToOrderInFuture')) {
      err = t(error.message, {
        date: lastPossibleOrderDayFormatted,
      });
    } else {
      err = t(error.message);
    }
    return err;
  };

  const CustomError = ({
    error,
    wrapper = true,
  }: {
    error?: Merge<FieldError, (FieldError | undefined)[]>;
    wrapper?: boolean;
  }) => {
    const translated = translatedError(error);
    if (!translated) return null;

    const Wrapper = ({ children }: { children: React.ReactNode }) => (
      <p className='text-destructive mb-6 text-sm font-medium'>{children}</p>
    );

    return wrapper ? <Wrapper>{translated}</Wrapper> : translated;
  };

  return (
    <section className='space-y-8'>
      <div className='space-y-4'>
        <div className='mt-0 flex flex-col justify-between md:flex-row md:items-center'>
          <h2 className='z-0 text-2xl'>{t('cart.configuration.chosenDiet')}</h2>
        </div>
        <DietConfigurationCard
          diet={diet}
          calorificsPrice={diet.basePrice}
          globals={globals}
        />
      </div>
      <div className='space-y-8'>
        <ConfigureVariant
          form={form}
          diet={diet}
          customError={
            !!form.formState.errors.meals
              ? translatedError(form.formState.errors.meals)
              : undefined
          }
        />
        <FormSection
          label={t('cart.configuration.orderDuration')}
          error={!!form.formState.errors.dates}
          id='cart-calendar'
        >
          <>
            {form.formState.errors.dates && (
              <CustomError error={form.formState.errors.dates} />
            )}
            <CartCalendar form={form} diet={diet} />
          </>
        </FormSection>
      </div>
    </section>
  );
};
