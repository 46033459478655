import { cn } from '@/lib/utils';

export const SectionHero = ({
  className,
  innerClassName,
  children,
}: {
  className?: string;
  innerClassName?: string;
  children: React.ReactNode;
}) => {
  return (
    <section
      className={cn(
        'lg:before:bg-primary relative before:absolute before:left-0 before:top-0 before:block before:w-full before:rounded-b-none md:before:h-32 md:before:rounded-b-xl',
        className,
      )}
    >
      <div
        className={cn(
          'relative mx-auto rounded-t-2xl bg-white p-4 md:-mx-6 md:p-6',
          innerClassName,
        )}
      >
        {children}
      </div>
    </section>
  );
};
