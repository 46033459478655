import { haveSaturdays, haveSundays } from '../utils/dates';
import { getVariantFromMealsAndCalories } from '../utils/variants';
import { type DietCatalogDetail } from '@/features/diets/types';
import { type DietVariant } from '@/features/diets/types/diet-variant.type';
import { type FiltersSetup } from '@/features/filters/types/filters-setup.type';
import { useFormResolver } from '@/lib/form-resolver';
import { useDietDates } from '@/lib/hooks/use-diet-dates';
import { isBefore } from 'date-fns';
import { isEmpty } from 'lodash';
import { type UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

const FormSchema = ({
  min = 1,
  max = 99,
  minMeals = 1,
}: {
  min?: number;
  max?: number;
  minMeals?: number;
}) =>
  z.object({
    dates: z
      .array(z.date().or(z.string()).pipe(z.coerce.date()))
      .min(min, { message: 'cart.configuration.errors.minDays' })
      .max(max, { message: 'cart.configuration.errors.maxDays' }),
    kcal: z
      .string({
        required_error: 'cart.configuration.errors.caloricityRequired',
      })
      .min(1, { message: 'cart.configuration.errors.caloricityRequired' })
      .or(z.number())
      .pipe(z.coerce.string()),
    meals: z
      .array(z.string(), {
        required_error: 'cart.configuration.errors.minMeals',
      })
      .min(minMeals, { message: 'cart.configuration.errors.minMeals' }),
    daysToSelect: z.number(),
    saturdays: z.boolean(),
    sundays: z.boolean(),
    variant: z.string().optional(),
  });

// nested validation
const RefinedSchema = ({
  min,
  max,
  minMeals,
  lastPossibleOrderDay,
}: {
  min?: number;
  max?: number;
  minMeals?: number;
  lastPossibleOrderDay?: Date | null;
}) =>
  FormSchema({ min, max, minMeals }).superRefine((val, ctx) => {
    if (val.dates.length !== val.daysToSelect) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `cart.configuration.errors.amountOfDays`,
        path: ['dates'],
      });
    }

    if (
      lastPossibleOrderDay &&
      val.dates.some((date) => !isBefore(date, lastPossibleOrderDay))
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `cart.configuration.errors.daysAllowedToOrderInFuture`,
        path: ['dates'],
      });
    }
  });

export const useConfigureDietForm = ({
  defaults,
  diet,
  min,
  max,
  minMeals,
}: {
  defaults?: {
    mealIds: string[];
    calories: number;
    dates?: string[];
    sourceCalorifics?: number;
  };
  diet: DietCatalogDetail;
  min?: number;
  max?: number;
  filtersSetup?: FiltersSetup;
  minMeals?: number;
}) => {
  // default values, used when there are no params or defaults
  const defaultConfiguration = {
    saturdays: false,
    sundays: false,
    days: 7,
  };

  // get next possible days for the current diet
  const { nextPossibleDays, lastPossibleOrderDay } = useDietDates({
    diet: diet,
    saturdays: defaultConfiguration.saturdays,
    sundays: defaultConfiguration.sundays,
  });

  const nextDays = nextPossibleDays(new Date(), defaultConfiguration.days);

  const baseVariant = diet?.base?.variant;
  const baseKcal = diet?.base?.caloricity?.toString();

  // variant selection
  let variant: DietVariant | undefined;

  if (defaults) {
    variant = getVariantFromMealsAndCalories(
      diet,
      defaults?.mealIds,
      defaults?.calories,
    );
  }

  // if there are any configuration from the initial state
  // for example - query params from listing or during editing the configuration (not adding to cart)
  const notEmpty = (defaults && !isEmpty(defaults)) || false;

  return useFormResolver({
    schema: RefinedSchema({
      min,
      max,
      minMeals,
      lastPossibleOrderDay,
    }),
    defaultValues: notEmpty
      ? {
          dates:
            defaults?.dates?.map((date: string) => new Date(date)) || nextDays,
          kcal:
            defaults?.sourceCalorifics?.toString() ??
            (defaults?.calories && defaults?.calories < +baseKcal
              ? baseKcal
              : defaults?.calories?.toString()) ??
            baseKcal ??
            '',

          meals: variant
            ? variant.mealTypes.map((mt) => mt.id)
            : defaults?.mealIds,
          daysToSelect: defaults?.dates?.length || defaultConfiguration.days,
          saturdays: haveSaturdays(defaults?.dates),
          sundays: haveSundays(defaults?.dates),
          variant: variant?.id || baseVariant || '',
        }
      : {
          dates: nextDays,
          kcal: baseKcal,
          meals: variant?.mealTypes.map((mt) => mt.id) || defaults?.mealIds,
          daysToSelect: defaultConfiguration.days,
          saturdays: defaultConfiguration.saturdays,
          sundays: defaultConfiguration.sundays,
          variant: baseVariant || '',
        },
  });
};

export type ConfigureDietFormType = z.infer<ReturnType<typeof RefinedSchema>>;
export type ConfigureDietReturnFormType = UseFormReturn<
  z.infer<ReturnType<typeof RefinedSchema>>
>;
