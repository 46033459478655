'use client';

import { InfoTooltip } from '@/components/common/info-tooltip';
import {
  type DietCalorificsMealTypesType,
  type DietCatalogDetail,
} from '@/features/diets/types';
import { useTranslations } from 'next-intl';
import React, { useEffect, useRef, useState } from 'react';

export const MealsButton = ({
  item,
  diet,
  kcal,
}: {
  item: DietCalorificsMealTypesType;
  diet: DietCatalogDetail;
  kcal: number;
}) => {
  const t = useTranslations();
  const currentCalorifics = diet.calorifics.find(
    (c) => c.calories === Number(kcal),
  );
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const meals = currentCalorifics?.mealsCaloricValue.find(
    (m) => m.mealId === item.mealId,
  );

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      const truncated = el.scrollWidth > el.clientWidth;
      setIsTruncated(truncated);
    }
  }, []);

  return (
    <div className='flex-1 truncate flex md:block justify-between'>
      <p
        className='text-base font-medium text-brand-green2 truncate'
        ref={textRef}
      >
        {isTruncated ? (
          <InfoTooltip content={item.name} asChild>
            <span>{item.name}</span>
          </InfoTooltip>
        ) : (
          item.name
        )}
      </p>
      <p className='text-sm text-brand-green2 shrink-0 mt-0.5 md:mt-0'>
        {t('cart.configuration.calorifics', { kcal: meals?.caloricity })}
      </p>
    </div>
  );
};
