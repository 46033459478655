import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';

export const TooltipWrapper = ({
  children,
  content,
  active = true,
}: {
  children: React.ReactNode;
  content: React.ReactNode;
  active?: boolean;
}) =>
  active ? (
    <TooltipProvider delayDuration={250}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span tabIndex={0}>{children}</span>
        </TooltipTrigger>
        <TooltipContent>{content}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    children
  );
