'use client';

import { Button } from '@/components/ui/button';
import { useScrollPosition } from '@/lib/hooks/use-scroll-position';
import { cn } from '@/lib/utils';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { type RefObject, useEffect, useState } from 'react';

export const FloatingBottomBar = ({
  button,
  content,
  hidingElementRef,
}: {
  button: {
    action?: () => void | Promise<void>;
    arrow?: boolean;
    className?: string;
    disabled?: boolean;
    form?: string;
    label: string | React.ReactNode;
    type?: 'button' | 'submit';
  };
  content: React.ReactNode;
  hidingElementRef: RefObject<HTMLDivElement | null>;
}) => {
  const [open, setOpen] = useState(true);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    if (!hidingElementRef.current) return;

    // height of the pricing element
    const pricingHeight = hidingElementRef.current?.clientHeight;

    // 8rem is the height of the header, so we need to add it to the offsetTop
    const headerHeight = 8 * 16;

    // height of the checkout bar
    const checkoutBarHeight = 88;

    // offset is the distance from the top of the page to the pricing element minus the height of the pricing element and the height of the checkout bar
    const offset =
      hidingElementRef.current?.offsetTop +
      headerHeight -
      pricingHeight -
      checkoutBarHeight;

    if (scrollPosition >= offset) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [hidingElementRef.current?.offsetTop, scrollPosition]);

  return (
    <div
      className={cn(
        'fixed bottom-0 left-0 z-10 w-full transition-all duration-200 ease-in-out sm:hidden',
        {
          'max-h-0 bg-transparent': !open,
          'h-[88px] max-h-[88px] border-t': open,
        },
      )}
    >
      <div className='bg-background flex items-center h-full p-4 pt-3'>
        <div className='h-14 w-full flex justify-center'>
          {content}
          <Button
            aria-disabled={button.disabled}
            form={button.form || undefined}
            className={cn(
              'ml-auto !px-12 py-3 text-base aria-disabled:pointer-events-none aria-disabled:cursor-not-allowed aria-disabled:opacity-50 h-14',
              button.className,
            )}
            onClick={button.action}
            type={button.type || 'button'}
            variant='secondary'
          >
            <span>{button.label}</span>
            {button.arrow && <IconArrowNarrowRight className='ml-3 ' />}
          </Button>
        </div>
      </div>
    </div>
  );
};
