import { type DietCatalogCateringType } from '@/features/diets/types';
import { useTranslations } from 'next-intl';

const EqualCost = ({
  price,
  t,
}: {
  price: DietCatalogCateringType['deliveryCosts']['price'];
  t: ReturnType<typeof useTranslations>;
}) => {
  return t('equal', { price });
};

const FromCost = ({
  price,
  t,
}: {
  price: DietCatalogCateringType['deliveryCosts']['price'];
  t: ReturnType<typeof useTranslations>;
}) => {
  return t('from', { price });
};

const FreeCost = ({ t }: { t: ReturnType<typeof useTranslations> }) => {
  return t('free');
};

const FromWithoutPriceCost = ({
  t,
}: {
  t: ReturnType<typeof useTranslations>;
}) => {
  return t('fromNoPrice');
};

export const DeliveryCost = ({
  type,
  price,
  align = 'right',
  hideNotFree = false,
  calculated = false,
}: DietCatalogCateringType['deliveryCosts'] & {
  align?: 'left' | 'right';
  hideNotFree?: boolean;
  calculated?: boolean;
}) => {
  const t = useTranslations('deliveryCosts');
  const Wrapper = (props: { children: React.ReactNode }) => (
    <div
      className='text-brand-green1 w-full my-1 leading-5 mb-2'
      style={{
        textAlign: align,
      }}
    >
      {props.children}
    </div>
  );

  if (calculated && hideNotFree && type !== 'free') {
    return null;
  }

  const DeliveryCostComponent = () => {
    switch (type) {
      case 'equal':
        return <EqualCost price={price} t={t} />;
      case 'from':
        return <FromCost price={price} t={t} />;
      case 'free':
        return <FreeCost t={t} />;
      case 'none':
        return null;
      case 'multiple':
        return <FromWithoutPriceCost t={t} />;
      default:
        return null;
    }
  };

  return DeliveryCostComponent ? (
    <Wrapper>
      <DeliveryCostComponent />
    </Wrapper>
  ) : null;
};
