'use client';

import { getIntlPrice } from '../../helpers/get-intl-price';
import { type CartPricing } from '../../types';
import { DeliveryCost } from '../delivery-cost';
import { BounceLoader } from '@/components/common/bounce-loader';
import { ButtonLoadingIcon } from '@/components/common/buttons/button-loading-icon';
import { FloatingBottomBar } from '@/components/floating-bottom-bar';
import { type DietCatalogCateringType } from '@/features/diets/types';
import { useTranslations } from 'next-intl';
import { type RefObject } from 'react';

export const ConfigurationBar = ({
  days,
  disabled,
  edit,
  loading,
  pricingRef,
  pricing,
  costs,
}: {
  days?: number;
  disabled?: boolean;
  edit: boolean;
  loading?: boolean;
  pricingRef: RefObject<HTMLDivElement | null>;
  pricing?: CartPricing;
  costs: DietCatalogCateringType['deliveryCosts'];
}) => {
  const t = useTranslations();

  const LoadingIcon = () =>
    disabled ? (
      <ButtonLoadingIcon className='ml-2.5 inline-block' loading={disabled} />
    ) : null;

  return (
    <FloatingBottomBar
      button={{
        arrow: !disabled,
        disabled: disabled || loading,
        form: 'configure-diet-form',
        label: edit ? (
          <>
            <span>{t('cart.configuration.save')}</span>
            <LoadingIcon />
          </>
        ) : (
          <>
            <span>{t('cart.configuration.addToCartSmall')}</span>
            <LoadingIcon />
          </>
        ),
        type: 'submit',
        className: 'px-6!',
      }}
      content={
        <div className='flex flex-col justify-center'>
          <span className='text-brand-violet text-lg font-bold leading-[26px]'>
            {loading ? (
              <BounceLoader className='my-2 h-4' />
            ) : (
              pricing?.final.gross && (
                <span>
                  <span className='font-bold'>
                    {getIntlPrice(pricing?.final.gross / (days || 1))}
                  </span>
                  <span> {t('cart.configuration.perDay')}</span>
                </span>
              )
            )}
          </span>
          <DeliveryCost {...costs} align='left' />
        </div>
      }
      hidingElementRef={pricingRef}
    />
  );
};
