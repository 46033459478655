import Check from '@/assets/icons/check.svg';
import { generateRandomUUID } from '@/lib/utilities';
import { Button } from '@repo/ui/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@repo/ui/components/ui/form';
import { cn } from '@repo/ui/lib/utils';
import { useTranslations } from 'next-intl';
import type { JSX } from 'react';
import {
  type ControllerRenderProps,
  type FieldValues,
  type Path,
  type UseFormReturn,
} from 'react-hook-form';

const layouts = {
  default: {
    field: '',
    button:
      'flex h-12 max-md:w-full gap-1 border-transparent px-3 py-4 font-normal max-md:justify-between md:w-auto hover:cursor-pointer',
    activeButton: 'border-brand-green-light border-2',
    label: 'flex items-center w-full max-md:justify-between gap-2',
    activeLabel: '',
    description: 'text-xs font-thin',
  },
  grid: {
    field: 'grid gap-4 sm:grid-cols-2 md:grid-cols-3 auto-rows-max',
    button:
      'flex flex-col items-start w-full h-full gap-1 px-3 py-4 text-wrap border-transparent font-normal justify-start hover:cursor-pointer',
    activeButton:
      'border-brand-green-light border-2 py-[calc(1rem-1px)] px-[calc(0.75rem-1px)]',
    label: 'w-full flex items-center md:justify-center gap-2',
    activeLabel: 'md:-ml-3',
    description: 'text-sm font-thin max-md:text-left w-full whitespace-normal',
  },
};

export const FormButtonSelect = <T extends FieldValues>({
  form,
  fieldName,
  fieldOptions,
  multi = false,
  className,
  classNameItem,
  classNameButton,
  classNameLabel,
  actionAfterChange,
  layout = 'default',
  customError,
}: {
  form: UseFormReturn<T, any, undefined>;
  fieldName: Path<T>;
  fieldOptions: {
    label: string | JSX.Element;
    value: string;
    description?: string;
  }[];
  multi?: boolean;
  className?: string;
  classNameItem?: string;
  classNameButton?: string;
  classNameLabel?: string;
  actionAfterChange?: (value: string) => void;
  layout?: keyof typeof layouts;
  customError?: string;
}) => {
  const t = useTranslations();

  const handleChange = (
    field: ControllerRenderProps<T, Path<T>>,
    value: string,
  ) => {
    if (multi) {
      if (!field.value) field.onChange([value]);
      else {
        field.value?.includes(value)
          ? field.onChange(field.value?.filter((v: string) => v !== value))
          : field.onChange([...field.value, value]);
      }
    } else {
      field.onChange(value);
    }

    form.trigger(fieldName);
    actionAfterChange?.(value);
  };

  const isActive = ({ value, option }: { value?: string; option: string }) => {
    if (multi) {
      return value?.includes(option);
    } else {
      return value === option;
    }
  };

  const Label = ({
    active,
    option,
  }: {
    active?: boolean;
    option: string | JSX.Element;
  }) =>
    layout === 'grid' ? (
      <span>
        {active && (
          <Check className='text-brand-green-light mr-2 inline-block h-4 w-4 max-md:hidden my-1 shrink-0' />
        )}
        {option}
        {active && (
          <Check className='text-brand-green-light ml-2 inline-block h-4 w-4 md:hidden my-1 shrink-0' />
        )}
      </span>
    ) : (
      <>
        {active && (
          <Check className='text-brand-green-light inline-block h-4 w-4 max-md:hidden my-1 shrink-0' />
        )}
        {option}
        {active && (
          <Check className='text-brand-green-light h-4 w-4 md:hidden my-1 shrink-0' />
        )}
      </>
    );

  const styling = layouts[layout];

  return (
    <FormField
      control={form.control}
      name={fieldName}
      render={() => (
        <FormItem className='w-full'>
          <FormControl>
            <div
              className={cn(
                'flex w-full flex-col flex-wrap gap-x-2 gap-y-2 md:gap-y-4 md:flex-row',
                styling.field,
                className,
              )}
            >
              {fieldOptions.map((option, index) => (
                <FormField
                  key={`${option.value}-${generateRandomUUID()}`}
                  control={form.control}
                  name={fieldName}
                  render={({ field }) => {
                    const active = isActive({
                      value: field.value,
                      option: option.value,
                    });

                    return (
                      <FormItem
                        key={option.value}
                        className={cn(
                          'flex w-full flex-col items-start space-x-3 space-y-0 md:w-auto md:flex-row',
                          classNameItem,
                        )}
                      >
                        <FormControl>
                          <Button
                            size='sm'
                            variant='outline'
                            onClick={() => handleChange(field, option.value)}
                            className={cn(
                              styling.button,
                              active && styling.activeButton,
                              classNameButton,
                            )}
                            type='button'
                            data-pw={`${fieldName}-${index}`}
                          >
                            <div
                              className={cn(
                                styling.label,
                                active && styling.activeLabel,
                                classNameLabel,
                              )}
                            >
                              <Label active={active} option={option.label} />
                            </div>
                            {option.description && (
                              <span className={styling.description}>
                                {option.description}
                              </span>
                            )}
                          </Button>
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              ))}
            </div>
          </FormControl>
          {customError ? (
            <p className='text-destructive mb-6 text-sm font-medium'>
              {customError}
            </p>
          ) : (
            <FormMessage t={t} />
          )}
        </FormItem>
      )}
    />
  );
};
