import { Loader } from '@/components/common/loader';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@/components/ui/card';
import { cn } from '@/lib/utils';

export type KeyValuePairProps = {
  label: string;
  value?: string | React.ReactNode;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
  testId?: string;
};

const KeyValuePair = ({
  label,
  value,
  className,
  labelClassName,
  valueClassName,
  testId,
}: KeyValuePairProps) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between text-base text-brand-green1',
        className,
      )}
      data-pw={testId}
    >
      <span className={labelClassName}>{label}</span>
      <span className={valueClassName} data-pw={`${testId}-value`}>
        {value ?? '-'}
      </span>
    </div>
  );
};

export const PriceElement = ({
  header,
  content,
  footer,
  loading,
}: {
  header: {
    icon?: React.ReactNode;
    label?: string;
  };
  content: {
    header?: React.ReactNode;
    kvs?: KeyValuePairProps[];
  };
  footer: {
    header?: React.ReactNode;
    kvs?: KeyValuePairProps[];
    buttons?: React.ReactNode;
    other?: React.ReactNode;
  };
  loading?: boolean;
}) => {
  return (
    <Card className='bg-background border p-0 md:sticky'>
      <CardHeader className='p-4'>
        <div className='flex items-center gap-2 text-xl font-medium'>
          {header.icon}
          {header.label}
        </div>
      </CardHeader>
      <CardContent className='bg-brand-grey1 space-y-2 p-4'>
        {content.header}
        <div className='flex flex-col gap-1'>
          {content.kvs?.map((kv) => (
            <KeyValuePair {...kv} key={`${kv.label}-${kv.value}`} />
          ))}
        </div>
      </CardContent>
      <CardFooter className='block w-full p-4'>
        {footer.header}
        <div className='flex flex-col gap-1'>
          {footer.kvs?.map((kv) => (
            <KeyValuePair {...kv} key={`${kv.label}-${kv.value}`} />
          ))}
        </div>
        {footer.other && <div className='w-full'>{footer.other}</div>}
        {footer.buttons}
      </CardFooter>
      {loading && (
        <div className='bg-brand-green1/10 absolute left-0 top-0 grid h-full w-full place-items-center rounded-lg'>
          <Loader />
        </div>
      )}
    </Card>
  );
};
