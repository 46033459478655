'use client';

import { Img } from '@/components/image';
import { ListingItemAttributes } from '@/components/listing/listing-item-attributes';
import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { DietListItem } from '@/features/diets/components/diet-list-item';
import { DietMenuDrawer } from '@/features/diets/components/diet-menu-drawer';
import { DiscountCodes } from '@/features/diets/components/discount-codes';
import { getDiscountDetails } from '@/features/diets/helpers/get-discount-details';
import {
  type DietCatalog,
  type DietCatalogDetail,
} from '@/features/diets/types';
import { Globals } from '@/features/globals/types/globals.type';
import { useDietDates } from '@/lib/hooks/use-diet-dates';
import { useTranslations } from 'next-intl';

export const DietConfigurationCard = ({
  diet,
  calorificsPrice,
  globals,
}: {
  diet: DietCatalogDetail;
  calorificsPrice?: number;
  globals?: Globals | null;
}) => {
  const t = useTranslations();
  const { hasDiscount, priceAfterDiscount } = getDiscountDetails({
    price: calorificsPrice ?? diet.basePrice,
    discountCodes: diet.discountCodes,
  });
  const dietDates = useDietDates({
    diet,
    saturdays: true,
    sundays: true,
  });

  return (
    <>
      {/* wide */}
      <div className='hidden md:block'>
        <DietListItem
          diet={diet}
          elements={{
            tags: true,
            description: true,
            menu: true,
            menuCta: false,
            heart: false,
            addToCart: false,
            availableOn: false,
            price: false,
            catering: false,
            rating: globals?.ratingDietListing ?? false,
          }}
          price={calorificsPrice}
        />
      </div>
      {/* mobile */}
      <div className='block md:hidden'>
        <Card className='bg-background'>
          <CardContent className='space-y-4 p-4'>
            <div className='bg-background flex items-center gap-2.5 rounded-lg'>
              <Img
                width={48}
                height={48}
                src={diet.imageUrl}
                alt={diet.name}
                className='h-12 w-12 rounded-lg object-cover'
              />
              <div className='flex flex-col'>
                <span className='text-sm'>{diet.name}</span>
                <span className='text-muted-foreground text-sm'>
                  {diet.catering.name}
                </span>
              </div>
            </div>
            <DiscountCodes discountCodes={diet.discountCodes} />
            <Separator />
            <div className='flex items-start gap-2'>
              <ListingItemAttributes
                label={t('diets.price_from')}
                className='block flex-1 items-end gap-2 whitespace-nowrap md:flex xl:block'
              >
                {!hasDiscount && (
                  <span>
                    <span className='-mt-1 inline-block text-xl font-bold leading-6'>
                      {t('common.price', {
                        price:
                          calorificsPrice?.toFixed(2) ??
                          diet.basePrice?.toFixed(2),
                      })}
                    </span>{' '}
                    / {t('diets.day')}
                  </span>
                )}
                {hasDiscount && (
                  <span className='flex flex-col'>
                    <span>
                      <span className='text-md mt-0 font-normal line-through'>
                        {t('common.price', {
                          price:
                            calorificsPrice?.toFixed(2) ??
                            diet.basePrice?.toFixed(2),
                        })}
                      </span>{' '}
                      / {t('diets.day')}
                    </span>
                    <span>
                      <span className='text-brand-red text-md font-bold'>
                        <span>
                          {t('common.price', {
                            price: priceAfterDiscount?.toFixed(2),
                          })}
                        </span>
                        <span className='font-normal'> / {t('diets.day')}</span>
                      </span>
                    </span>
                  </span>
                )}
              </ListingItemAttributes>
              <ListingItemAttributes
                label={t('diets.available_on')}
                className='block flex-1 items-end gap-2 md:flex xl:block'
              >
                <span className='capitalize'>
                  {dietDates.nextPossibleDayFormatEeee(new Date())}
                </span>
              </ListingItemAttributes>
            </div>
            <DietMenuDrawer
              dietId={diet.id}
              label={t('diets.check_menu')}
              showCta={false}
            />
          </CardContent>
        </Card>
      </div>
    </>
  );
};
