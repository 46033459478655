import { Img } from '../image';
import { Link } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { type HTMLAttributes } from 'react';

type ListingItemImageProps = HTMLAttributes<HTMLDivElement> & {
  name: string;
  imageUrl: string;
  imageClassName?: string;
  url?: string;
};

export const ListingItemImage = ({
  name,
  imageUrl,
  className,
  imageClassName,
  children,
  url,
  ...props
}: ListingItemImageProps) => {
  const styling = cn(
    'bg-brand-grey2 relative flex min-h-[210px] items-center justify-center rounded-tl-lg rounded-tr-lg md:w-60 md:rounded-bl-lg md:rounded-tr-none h-full',
    className,
  );

  const Wrapper = ({ children }: { children: React.ReactNode }) =>
    url ? (
      <Link href={url} className={styling}>
        {children}
      </Link>
    ) : (
      <div className={styling} {...props}>
        {children}
      </div>
    );

  return (
    <Wrapper>
      {imageUrl && (
        <Img
          src={imageUrl}
          alt={name}
          fill
          sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
          className={cn(
            'rounded-tl-lg rounded-tr-lg object-cover object-center md:rounded-bl-lg md:rounded-tr-none',
            imageClassName,
          )}
        />
      )}
      {children}
    </Wrapper>
  );
};
