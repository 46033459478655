import { Card, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import type { JSX } from 'react';

export const FormSection = ({
  label,
  children,
  error = false,
  id,
}: {
  label: string;
  children: JSX.Element;
  error?: boolean;
  id?: string;
}) => {
  return (
    <section className='w-full space-y-2' id={id}>
      <h2 className='text-2xl'>{label}</h2>
      <Card
        className={cn('bg-brand-grey1 border-transparent', {
          'border-brand-red': error,
        })}
      >
        <CardContent className='p-4 md:p-6'>{children}</CardContent>
      </Card>
    </section>
  );
};
