import { type DietCatalog } from '../types';
import { DietDetails } from './diet-datails';
import { type TriggerProps } from '@/components/types';
import { cn } from '@/lib/utils';
import { Drawer } from '@repo/ui/components/drawer';
import { useTranslations } from 'next-intl';
import type { JSX } from 'react';

type DietDetailsProps = {
  dietId: string;
  label: JSX.Element;
  trigger?: TriggerProps;
  showCta?: boolean;
};

export const DietDetailsDrawer = ({
  dietId,
  label,
  trigger,
  showCta,
}: DietDetailsProps) => {
  const t = useTranslations('diets');
  return (
    <Drawer
      trigger={{
        label: label,
        variant: 'link',
        ...trigger,
        className: cn(
          'flex-1 no-underline group relative p-0  min-h-0 h-auto leading-6 whitespace-normal text-left',
          trigger?.className,
        ),
      }}
      side='left'
      cover={true}
      className='border-r-0 sm:w-1/2 sm:min-w-[640px] sm:max-w-full [&>button]:left-6 [&>button]:top-4 md:[&>button]:left-auto md:[&>button]:right-10 md:[&>button]:top-6'
      contentClassName='p-0'
      title={t('description')}
      description={' '}
      headerClassName='md:hidden h-[60px] fixed top-0 left-0 w-full bg-white overflow-hidden sm:max-w-[640px]'
    >
      <DietDetails dietId={dietId} share={false} showCta={showCta} />
    </Drawer>
  );
};
