import ClassicLoader from '../classic-loader';
import type { JSX } from 'react';

export const ButtonLoadingIcon = ({
  icon,
  loading,
  className,
}: {
  icon?: JSX.Element;
  loading: boolean;
  className?: string;
}) => {
  if (loading) return <ClassicLoader className={className} />;

  return icon || null;
};
