'use client';

import { FormCheckbox } from '@/components/form';
import { FormCounterInput } from '@/components/form/form-counter-input';
import { FormDatePicker } from '@/components/form/form-date-picker';
import { type DietCatalogDetail } from '@/features/diets/types';
import { useDietDates } from '@/lib/hooks/use-diet-dates';
import { useTranslations } from 'next-intl';
import { type UseFormReturn } from 'react-hook-form';

export const CartCalendar = ({
  form,
  diet,
}: {
  form: UseFormReturn<any, any, undefined>;
  diet: DietCatalogDetail;
}) => {
  const t = useTranslations();
  const daysToSelect = form.watch('daysToSelect');
  const dietDates = useDietDates({
    diet,
    saturdays: form.getValues('saturdays'),
    sundays: form.getValues('sundays'),
  });

  return (
    <div className='space-y-4'>
      <div className='flex w-full flex-col gap-4 max-md:justify-between md:flex-row md:items-center'>
        <FormCounterInput
          form={form}
          fieldName='daysToSelect'
          sufix={'days'}
          layout='big'
          fieldPlaceholder={t('filters.labels.days')}
        />
        {(diet.catering.saturdayDelivery || diet.catering.sundayDelivery) && (
          <div className='flex items-center gap-2 max-md:mt-2 sm:gap-4 md:ml-auto md:flex-row'>
            <span className='font-medium'>{t('cart.calendar.include')}</span>
            {diet.catering.saturdayDelivery && (
              <FormCheckbox
                form={form}
                fieldName='saturdays'
                fieldLabel={t('cart.calendar.saturdays')}
                data-pw='saturdays-checkbox'
              />
            )}
            {diet.catering.sundayDelivery && (
              <FormCheckbox
                form={form}
                fieldName='sundays'
                fieldLabel={t('cart.calendar.sundays')}
                data-pw='sundays-checkbox'
              />
            )}
          </div>
        )}
      </div>
      <FormDatePicker
        form={form}
        fieldName='dates'
        fieldNameDaysToSelect='daysToSelect'
        daysToSelect={daysToSelect}
        disableSaturdays={!form.getValues('saturdays')}
        disableSundays={!form.getValues('sundays')}
        firstPossibleOrderDay={dietDates.nextPossibleDay(new Date())}
        disabledDays={diet.catering.disabledDays.map((day) => new Date(day))}
        showMessage={false}
      />
    </div>
  );
};
