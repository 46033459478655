import CryptoJS from 'crypto-js';

const secretKey = 'superSecretKey';

export const encryptData = <T>(data: T): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = <T>(ciphertext: string): T => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
