import { type HTMLAttributes } from 'react';

type ListingItemAttributesProps = HTMLAttributes<HTMLDivElement> & {
  label?: string;
  children: React.ReactNode;
};

export const ListingItemAttributes = ({
  label,
  children,
  ...props
}: ListingItemAttributesProps) => {
  return (
    <div {...props}>
      {label && (
        <p className='mb-0.5 text-[11px] font-bold uppercase text-black/50'>
          {label}
        </p>
      )}
      <div className='text-base leading-6'>{children}</div>
    </div>
  );
};
