'use client';

import { type DietCatalogDiscountType } from '../types';
import Copy from '@/assets/icons/copy.svg';
import Discount from '@/assets/icons/discount.svg';
import { Badge } from '@/components/ui/badge';
import { toast } from '@/components/ui/sonner';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const DiscountCodes = ({
  discountCodes,
  codeCopy,
  className,
  breakSpaces = false,
}: {
  discountCodes?: DietCatalogDiscountType[];
  codeCopy?: boolean;
  className?: string;
  breakSpaces?: boolean;
}) => {
  const t = useTranslations('diets');
  if (!discountCodes) return null;
  if (discountCodes.length === 0) return null;

  const Code = ({ amount, unit, name }: DietCatalogDiscountType) =>
    codeCopy ? (
      <span>
        <span className='whitespace-break-spaces'>
          {t('discountCodeWithoutCode', {
            amount: amount,
            unit: unit,
          })}
        </span>
        <CopyToClipboard
          text={name}
          onCopy={() =>
            toast.success(
              <div className='flex items-center gap-0.5'>
                <Discount className='mr-1 inline-block h-4 min-h-4 w-4 min-w-4' />
                <span>
                  <span>{t('discountCodeCopied')}</span>
                  <span className='font-bold'>{name}</span>
                </span>
              </div>,
            )
          }
        >
          <span className='ml-0.5 cursor-pointer font-bold'>
            {name}
            <Copy className='ml-0.5 inline-block h-4 min-h-4 w-4 min-w-4' />
          </span>
        </CopyToClipboard>
      </span>
    ) : (
      <span
        className={cn({
          'whitespace-break-spaces': breakSpaces,
          'overflow-clip whitespace-nowrap': !breakSpaces,
        })}
      >
        {t('discountCode', {
          amount: amount,
          unit: unit,
          name: name,
        })}
      </span>
    );

  return (
    discountCodes.map((discountCode) => (
      <Badge
        key={discountCode.name}
        variant='outline'
        className={cn(
          'bg-brand-red/10 text-brand-red rounded-full border-0 px-3 py-2 md:px-2 md:py-1.5',
          className,
        )}
      >
        <p className='flex items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal leading-4 md:text-[11px] md:font-medium'>
          <Discount className='h-4 min-h-4 w-4 min-w-4' />
          <Code {...discountCode} />
        </p>
      </Badge>
    )) || null
  );
};
