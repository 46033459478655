import {
  type DietCatalog,
  type DietCatalogDetail,
} from '@/features/diets/types';

export const getVariantFromMeals = (
  diet?: DietCatalogDetail | DietCatalog,
  meals?: string[],
) =>
  diet?.variants?.find((variant) =>
    variant.mealTypes.every((mealType) => meals?.includes(mealType.id)),
  );

export const getVariantFromMealsCount = (
  diet?: DietCatalogDetail | DietCatalog,
  meals?: string,
) => {
  return diet?.variants?.find(
    (variant) => variant.mealTypes.length === Number(meals),
  );
};

export const getVariantFromMealsAndCalories = (
  diet?: DietCatalogDetail | DietCatalog,
  meals?: string[] | string,
  calories?: number,
) => {
  return (
    diet?.variants?.find((variant) => {
      // Check if all meals are included in the mealTypes of the variant
      let hasAllMeal = false;
      if (Array.isArray(meals)) {
        const mealTypeIds = variant.mealTypes.map((mealType) => mealType.id);

        // If the number of meals is different, the variant is not a match
        if (meals?.length !== variant.mealTypes.length) return false;

        hasAllMeal = meals?.every((id) => mealTypeIds.includes(id));
      } else {
        hasAllMeal = Number(meals) === variant.mealTypes.length;
      }
      // Check if calorifics contains the given calories value
      const hasMatchingCalories = variant.calorifics.some(
        (calorific) => calorific.calories === calories,
      );

      return hasAllMeal && hasMatchingCalories;
    }) || undefined
  );
};

export const getVariant = (
  diet?: DietCatalogDetail | DietCatalog,
  variantId?: string,
) => diet?.variants?.find((variant) => variant.id === variantId);

export const getVariantFromCaloricity = (
  diet?: DietCatalogDetail | DietCatalog,
  kcal?: string | number,
) => {
  // keep only numbers
  const parsedKcal = kcal?.toString().match(/\d+/)?.[0];
  const hasGreaterOrEqual = kcal?.toString().includes('≥');

  const exactMatch = diet?.variants?.find((variant) =>
    variant.calorifics.some(
      (calorific) => calorific.calories.toString() === parsedKcal?.toString(),
    ),
  );

  if (exactMatch) return exactMatch;

  const targetKcal = Number(parsedKcal);
  if (!targetKcal || !diet?.variants) return undefined;

  let closestVariant = undefined;
  let smallestDifference = Infinity;

  diet.variants.forEach((variant) => {
    variant.calorifics.forEach((calorific) => {
      const difference = Math.abs(calorific.calories - targetKcal);

      if (hasGreaterOrEqual && calorific.calories < targetKcal) {
        return;
      }

      if (
        difference < smallestDifference ||
        (difference === smallestDifference && calorific.calories < targetKcal)
      ) {
        smallestDifference = difference;
        closestVariant = variant;
      }
    });
  });

  return closestVariant;
};
