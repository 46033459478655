import { type ConfigureDietReturnFormType } from '../forms/configure-diet';
import { type CartItem } from '../types';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

type UseDietParams = {
  dietId: string;
  merchantId: string;
  form: ConfigureDietReturnFormType;
};

export const useItemPrice = ({ dietId, merchantId, form }: UseDietParams) => {
  const { watch } = form;
  const kcal = watch('kcal');
  const dates = watch('dates')?.map((date: Date) => format(date, 'yyyy-MM-dd'));
  const mealIds = watch('meals');

  return useQuery({
    queryKey: ['item-price', { dietId, merchantId, kcal, dates, mealIds }],
    queryFn: () =>
      fetch('/api/cart/item-price', {
        method: 'POST',
        body: JSON.stringify({
          configuration: {
            calories: +kcal,
            mealIds,
            dates,
            dietId,
            merchantId,
            parameters: {
              ecoContainers: false,
            },
            contact: {
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
            },
          },
        }),
      }).then((res) => res.json()) as Promise<CartItem>,
  });
};
