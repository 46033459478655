import MinusIcon from '@/assets/icons/minus.svg';
import PlusIcon from '@/assets/icons/plus.svg';
import { Button } from '@/components/ui/button';
import { FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { useRef } from 'react';
import {
  type ControllerRenderProps,
  type FieldValues,
  type Path,
  type PathValue,
  type UseFormReturn,
} from 'react-hook-form';

const layouts = {
  default: {
    formItemClasses: '',
    buttonClasses: 'bg-brand-grey1 z-1 h-8 absolute border-0',
    inputClasses: ' w-full px-12 text-center',
  },
  big: {
    formItemClasses: '',
    buttonClasses:
      'bg-brand-grey1 z-1 h-12 md:h-14 absolute w-12 px-0 border-0',
    inputClasses: ' w-full px-14 text-center h-14 md:h-16',
  },
  medium: {
    formItemClasses: '',
    buttonClasses:
      'bg-brand-grey1 z-1 h-12 md:h-12 absolute w-12 px-0 border-0',
    inputClasses: ' w-full px-14 text-center h-14',
  },
};

type FormCounterInputProps<T extends FieldValues> = {
  form: UseFormReturn<T, any, undefined>;
  fieldName: Path<T>;
  sufix?: string;
  layout?: keyof typeof layouts;
  fieldPlaceholder?: string;
};

export const FormCounterInput = <T extends FieldValues>({
  form,
  fieldName,
  layout = 'default',
  fieldPlaceholder,
  sufix,
}: FormCounterInputProps<T>) => {
  const { setValue, getValues } = form;
  const inputRef = useRef<HTMLInputElement>(null);
  const t = useTranslations('dateTime');

  const handleIncrement = () => {
    const currentDays = Number(getValues(fieldName)) || 0;

    setValue(fieldName, (currentDays + 1) as PathValue<T, Path<T>>);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(
          currentDays.toString().length,
          currentDays.toString().length,
        );
      }
    }, 0);
  };

  const handleDecrement = () => {
    const currentDays = Number(getValues(fieldName)) || 0;
    if (currentDays > 1) {
      setValue(fieldName, (currentDays - 1) as PathValue<T, Path<T>>);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(
            currentDays.toString().length,
            currentDays.toString().length,
          );
        }
      }, 0);
    }
  };

  const formatDays = (value: string | number): string => {
    const number = parseInt(value.toString(), 10);
    return isNaN(number) ? '' : t(sufix, { count: number });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<T, Path<T>>,
  ) => {
    const value = e.target.value.replace(/\D/g, '');
    field.onChange(value ? parseInt(value, 10) : '');
    setTimeout(() => {
      const cursorPos = value.length;
      if (inputRef.current) {
        inputRef.current.setSelectionRange(cursorPos, cursorPos);
      }
    }, 0);
  };

  return (
    <div className='relative flex items-center'>
      <Button
        type='button'
        variant='outline'
        className={cn('left-1.5', layouts[layout].buttonClasses)}
        onClick={handleDecrement}
        data-pw='decrease-counter'
      >
        <MinusIcon width={17} height={2} alt='-' />
      </Button>
      <FormField
        control={form.control}
        name={fieldName}
        render={({ field }) => (
          <FormItem className={cn('flex-1', layouts[layout].formItemClasses)}>
            <Input
              {...field}
              type='text'
              ref={inputRef}
              value={field.value && formatDays(field.value)}
              onChange={(e) => handleInputChange(e, field)}
              onBlur={() => {
                if (field.value === '') {
                  setValue(fieldName, 1 as PathValue<T, Path<T>>);
                }
              }}
              className={layouts[layout].inputClasses}
              placeholder={fieldPlaceholder}
              data-pw='days-counter'
            />
          </FormItem>
        )}
      />
      <Button
        type='button'
        variant='outline'
        className={cn('right-1.5', layouts[layout].buttonClasses)}
        onClick={handleIncrement}
        data-pw='increase-counter'
      >
        <PlusIcon width={17} height={16} alt='+' />
      </Button>
    </div>
  );
};
