'use client';

import { type ConfigureDietReturnFormType } from '../../forms/configure-diet';
import { FormSection } from './configure-form-section';
import { MealsBar } from './meals-bar';
import { MealsButton } from './meals-button';
import { FormButtonSelect } from '@/components/form/form-button-select';
import { type DietCatalogDetail } from '@/features/diets/types';
import { useTranslations } from 'next-intl';

export const ConfigureSupervariants = ({
  form,
  diet,
  customError,
}: {
  form: ConfigureDietReturnFormType;
  diet: DietCatalogDetail;
  customError?: string;
}) => {
  const t = useTranslations();

  return (
    <div className='space-y-8'>
      <FormSection label={t('cart.configuration.caloricity')}>
        <div className='flex w-full flex-col items-start gap-2 md:flex-row md:items-center'>
          <FormButtonSelect
            fieldName='kcal'
            form={form}
            fieldOptions={diet.calorifics
              .sort((a, b) => a.calories - b.calories)
              .map((item) => ({
                label: (
                  <span className='flex w-full items-center justify-between md:block'>
                    <span>{t('common.kcal', { kcal: item.calories })}</span>
                    <span className='space-x-1 md:hidden'>
                      <span>{t('cart.configuration.priceLowerCase')}:</span>
                      <span className='font-bold'>
                        {item.price.toFixed(2)} zł
                      </span>
                      <span>{t('cart.configuration.perDay')}</span>
                    </span>
                  </span>
                ),
                value: item.calories.toString(),
              }))}
          />
        </div>
      </FormSection>

      <FormSection
        label={t('cart.configuration.numberOfMeals')}
        error={form.getFieldState('meals').invalid}
        id='cart-meals'
      >
        <>
          <div className='flex w-full space-x-2'>
            <FormButtonSelect
              fieldName='meals'
              multi
              classNameButton='flex flex-col md:h-[70px] text-left w-full md:w-full py-[10px] md:py-4'
              classNameLabel='items-start'
              className='w-full'
              classNameItem='flex-1 min-w-0'
              form={form}
              fieldOptions={
                diet.calorifics
                  .find((c) => c.calories === Number(form.getValues('kcal')))
                  ?.mealsCaloricValue.map((item) => ({
                    label: (
                      <MealsButton
                        item={item}
                        diet={diet}
                        kcal={Number(form.getValues('kcal'))}
                      />
                    ),
                    value: item.mealId,
                  })) || []
              }
              customError={customError}
            />
          </div>
          <MealsBar form={form} diet={diet} />
        </>
      </FormSection>
    </div>
  );
};
