import { type KeyValuePairProps } from '../components/pricing/price-element';
import { type Discount } from '../types/discount.type';
import { cn } from '@/lib/utils';
import { type TranslationValues } from 'next-intl';

function getGroupedDiscounts(discounts?: Discount[]): Discount[] {
  if (!discounts) return [];

  const groupedDiscounts: Discount[] = [];

  discounts.forEach((discount) => {
    const existingDiscount = groupedDiscounts.find(
      (grouped) => discount.name === grouped.name,
    );

    if (existingDiscount) {
      existingDiscount.amount.gross += discount.amount.gross;
      existingDiscount.amount.net += discount.amount.net;
      existingDiscount.amount.tax += discount.amount.tax;
    } else {
      groupedDiscounts.push({
        name: discount.name,
        amount: {
          gross: discount.amount.gross,
          net: discount.amount.net,
          tax: discount.amount.tax,
        },
      });
    }
  });
  return groupedDiscounts;
}

export const DiscountsKV = ({
  discounts,
  t,
  className,
  valueClassName,
}: {
  discounts?: Discount[];
  t: (key: string, values?: TranslationValues) => string;
  className?: string;
  valueClassName?: string;
}): KeyValuePairProps[] => {
  const discountsGrouped = getGroupedDiscounts(discounts);
  return (
    discountsGrouped?.map((discount) => ({
      label: discount.name,
      value: t('common.price', {
        price: '-' + discount.amount.gross.toFixed(2),
      }),
      valueClassName: cn('text-brand-red font-bold', valueClassName),
      className: cn('font-normal', className),
    })) || []
  );
};
