'use client';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { useState } from 'react';

export const InfoTooltip = ({
  content,
  children,
  asChild = false,
}: {
  content: string;
  children?: React.ReactNode;
  asChild?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const isMobile = window && window.innerWidth < 768;

  return (
    <TooltipProvider>
      <Tooltip open={open} onOpenChange={setOpen}>
        <TooltipTrigger
          asChild={asChild}
          onClick={(e) => {
            isMobile && setOpen(true);
            e.preventDefault();
          }}
          className={cn(
            'truncate block max-w-full',
            !children &&
              'bg-brand-violet flex h-[14px] w-[14px] items-center justify-center rounded-full text-xs text-white ',
          )}
        >
          {children ? children : '?'}
        </TooltipTrigger>
        <TooltipContent asChild>
          <div className='w-auto max-w-[340px] px-4! py-3! text-left'>
            {content}
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
