import { cn } from '@/lib/utils';
import { type HTMLAttributes } from 'react';

type LoaderProps = HTMLAttributes<HTMLDivElement> & { innerClassName?: string };

export const BounceLoader = ({
  className,
  innerClassName,
  ...props
}: LoaderProps) => {
  return (
    <div
      className={cn('flex items-center justify-center space-x-1', className)}
      {...props}
    >
      <div
        className={cn(
          'bg-primary h-1.5 w-1.5 animate-bounce rounded-full [animation-delay:-0.3s]',
          innerClassName,
        )}
      ></div>
      <div
        className={cn(
          'bg-primary h-1.5 w-1.5 animate-bounce rounded-full [animation-delay:-0.13s]',
          innerClassName,
        )}
      ></div>
      <div
        className={cn(
          'bg-primary h-1.5 w-1.5 animate-bounce rounded-full',
          innerClassName,
        )}
      ></div>
    </div>
  );
};
