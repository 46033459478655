import { type DietCatalog } from '../types';
import { DietMenu } from './diet-menu';
import { Drawer } from '@repo/ui/components/drawer';
import { useTranslations } from 'next-intl';
import type { JSX } from 'react';

type DietDetailsProps = {
  dietId: string;
  label: string | JSX.Element;
  showCta?: boolean;
};

export const DietMenuDrawer = ({
  dietId,
  label,
  showCta,
}: DietDetailsProps) => {
  const t = useTranslations('diets');
  return (
    <Drawer
      trigger={{
        label: label,
        variant: 'outline',
        className:
          'border-brand-green2 text-brand-green2 h-12 min-h-0 w-full rounded-lg px-0 md:px-5 font-medium md:h-10 xl:h-12',
      }}
      side='left'
      cover={true}
      className='border-r-0 sm:w-1/2 sm:min-w-[640px] sm:max-w-full [&>button]:left-6 [&>button]:top-4 md:[&>button]:left-auto md:[&>button]:right-10 md:[&>button]:top-6'
      contentClassName='p-0'
      title={t('menu')}
      description={' '}
      headerClassName='md:hidden h-[60px] fixed top-0 left-0 w-full bg-white overflow-hidden sm:max-w-[640px]'
    >
      <DietMenu dietId={dietId} share={false} showCta={showCta} />
    </Drawer>
  );
};
