import { type DietCatalog, type DietCatalogDetail } from '../types';
import { type DietPricingComplex } from '../types/diet-pricing-complex.type';
import { DietDetailsDrawer } from './diet-details-drawer';
import { DietListItemPricing } from './diet-list-item-pricing';
import { DietMenuDrawer } from './diet-menu-drawer';
import { DiscountCodes } from './discount-codes';
import HeartIcon from '@/assets/icons/heart.svg';
import { Rating } from '@/components/common/rating';
import { UnderlineAnimation } from '@/components/common/underline-animation';
import { ListingItemAttributes } from '@/components/listing/listing-item-attributes';
import { ListingItemCateringImage } from '@/components/listing/listing-item-catering-image';
import { ListingItemHeader } from '@/components/listing/listing-item-header';
import { ListingItemImage } from '@/components/listing/listing-item-image';
import { Badge } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
  Link,
  type LocalizedRoute,
  getLocaleForPathname,
  getPathname,
} from '@/i18n/navigation';
import { useDietDates } from '@/lib/hooks/use-diet-dates';
import {
  type FilterableParams,
  getConfiguratorParams,
  objectToQueryString,
} from '@/lib/utilities';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';

type CatalogListItemElements = {
  addToCart?: boolean;
  availableOn?: boolean;
  catering?: boolean;
  description?: boolean;
  heart?: boolean;
  menu?: boolean;
  menuCta?: boolean;
  price?: boolean;
  rating?: boolean;
  ratingDetails?: boolean;
  tags?: boolean;
  discountCodes?: boolean;
  discountCodesCopy?: boolean;
  content?: boolean;
};

const defaultElements = {
  addToCart: true,
  availableOn: true,
  catering: true,
  description: true,
  heart: true,
  menu: true,
  menuCta: true,
  price: true,
  rating: true,
  ratingDetails: false,
  tags: true,
  discountCodes: true,
  discountCodesCopy: false,
  content: true,
};

type DietListItemProps = {
  diet: DietCatalog | DietCatalogDetail;
  elements?: CatalogListItemElements;
  price?: number;
  priceComplex?: DietPricingComplex;
  imageClassName?: string;
  customActions?: React.ReactNode;
  className?: string;
  searchParams?: Record<string, string | string[]>;

  // custom things
  custom?: {
    tags?: React.ReactNode;
  };
};

export const DietListItem = ({
  diet,
  elements = defaultElements,
  price,
  priceComplex,
  imageClassName,
  customActions,
  className,
  searchParams,
  custom,
}: DietListItemProps) => {
  const t = useTranslations();
  const elementsConfig = { ...defaultElements, ...elements };
  const locale = getLocaleForPathname();
  const configParams = getConfiguratorParams<FilterableParams>({
    ...searchParams,
    configuration: diet.searchResultId || '',
  });
  const dietDates = useDietDates({ diet, saturdays: true, sundays: true });

  return (
    <Card
      className={cn(
        'border-brand-grey2 relative flex w-full flex-col rounded-lg border shadow-none md:flex-row',
        className,
      )}
    >
      <div className='relative'>
        <ListingItemImage
          name={diet.name}
          imageUrl={diet.imageUrl}
          className={imageClassName}
          url={
            getPathname({
              href: {
                pathname: '/configure-diet/[slug]',
                params: { slug: diet.slug },
              },
              locale,
            }) + objectToQueryString(configParams)
          }
        />
        {elementsConfig.rating && (
          <div className='absolute bottom-2 left-2 flex gap-x-2'>
            <Rating
              productId={diet.id}
              productName={diet.name}
              rating={diet.rating.value}
              count={diet.rating.count}
              isTrending={diet.rating.isTrending}
              details={elementsConfig.ratingDetails}
              size='md'
              className='bg-white md:hidden'
            />
          </div>
        )}
        {customActions && (
          <div className='absolute right-2 top-2 md:hidden'>
            {customActions}
          </div>
        )}
      </div>
      <CardContent className='flex flex-1 flex-col p-4 xl:py-6'>
        <div className='flex gap-4'>
          <ListingItemCateringImage
            name={diet.catering.name}
            imageUrl={diet.catering.logo.url}
            url={
              getPathname({
                href: {
                  pathname: '/caterings/[slug]',
                  params: { slug: diet.catering.slug },
                },
                locale,
              }) as LocalizedRoute
            }
            className='absolute left-2 top-2 max-md:h-12 max-md:w-12 md:static'
          />
          <div className='flex w-0 grow flex-col md:gap-1'>
            <div
              className={cn('order-2 flex justify-between md:mt-0', {
                'mt-3': elementsConfig.tags && diet.tags.length > 0,
              })}
            >
              <ListingItemHeader
                componentPrimary={
                  <DietDetailsDrawer
                    dietId={diet.id}
                    label={
                      <>
                        {diet.name}
                        <UnderlineAnimation />
                      </>
                    }
                    trigger={{ className: 'mr-2' }}
                    showCta={elementsConfig.menuCta}
                  />
                }
                urlSecondary={getPathname({
                  href: {
                    pathname: '/caterings/[slug]',
                    params: { slug: diet.catering.slug },
                  },
                  locale,
                })}
                nameSecondary={diet.catering.name}
              />
              {elementsConfig.heart && (
                <Button
                  variant='ghost'
                  className='absolute right-2 top-2 h-auto min-h-0 rounded-full bg-white px-3 py-3 hover:text-red-700 md:static md:p-0 md:hover:bg-transparent **:hover:fill-red-700'
                >
                  <HeartIcon />
                </Button>
              )}
            </div>

            {((elementsConfig.tags && diet.tags.length > 0) ||
              elementsConfig.rating ||
              (elementsConfig.discountCodes && diet.discountCodes)) && (
              <ScrollArea className='order-1 w-full overflow-x-auto'>
                <div className='flex flex-nowrap gap-1.5'>
                  {elementsConfig.discountCodes && diet.discountCodes && (
                    <DiscountCodes
                      discountCodes={diet.discountCodes}
                      codeCopy={elementsConfig.discountCodesCopy}
                      className='order-2'
                    />
                  )}
                  {elementsConfig.rating && (
                    <Rating
                      rating={diet.rating.value}
                      count={diet.rating.count}
                      isTrending={diet.rating.isTrending}
                      details={elementsConfig.ratingDetails}
                      productId={diet.id}
                      productName={diet.name}
                      size='md'
                      className='hidden md:block'
                      ratingClassName='order-1'
                      trendingClassName='order-3'
                    />
                  )}
                  {elementsConfig.tags &&
                    diet.tags.map((tag, index) => (
                      <Badge
                        key={tag.slug + index.toString()}
                        variant='outline'
                        className='bg-brand-brown/10 text-brand-brown rounded-full border-0 px-3 py-2 md:px-2 md:py-1.5 order-4'
                      >
                        <p className='flex items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium leading-4 md:text-[11px]'>
                          {t(`dietTags.${tag.slug}`)}
                        </p>
                      </Badge>
                    ))}
                </div>
                <ScrollBar orientation='horizontal' className='hidden' />
              </ScrollArea>
            )}

            {elementsConfig.tags && custom?.tags && (
              <ScrollArea className='order-1 w-full overflow-x-auto max-md:mb-2'>
                <div className='flex flex-nowrap gap-1.5'>{custom.tags}</div>
                <ScrollBar orientation='horizontal' className='hidden' />
              </ScrollArea>
            )}
          </div>
        </div>
        {elementsConfig.description && diet.description && (
          <div
            className='mb-5 mt-3 line-clamp-2 text-sm md:mb-3 xl:mb-5'
            dangerouslySetInnerHTML={{ __html: diet.description }}
          />
        )}
        {elementsConfig.content && (
          <div className='mt-auto flex flex-wrap items-center justify-between gap-5 md:gap-2 xl:flex-nowrap'>
            <div className='flex grow basis-full gap-4 lg:gap-4 xl:basis-1/2'>
              {elementsConfig.price && (
                <DietListItemPricing
                  price={price ?? diet.basePrice}
                  priceComplex={priceComplex}
                  discountCodes={diet.discountCodes}
                />
              )}
              {elementsConfig.availableOn && (
                <ListingItemAttributes
                  label={t('diets.available_on')}
                  className='block flex-1 items-end gap-2'
                >
                  <span className='capitalize'>
                    {dietDates.nextPossibleDayFormatEeee(new Date())}
                  </span>
                </ListingItemAttributes>
              )}
            </div>
            <div
              className={cn('flex basis-full gap-2 xl:basis-auto', {
                'hidden md:flex':
                  !elementsConfig.menu &&
                  !elementsConfig.addToCart &&
                  customActions,
              })}
            >
              {elementsConfig.menu && (
                <div className='flex-1' data-pw='check-menu-button'>
                  <DietMenuDrawer
                    dietId={diet.id}
                    label={t('diets.check_menu')}
                    showCta={elementsConfig.menuCta}
                  />
                </div>
              )}
              {elementsConfig.addToCart && (
                <div className='flex-1'>
                  <Link
                    data-pw='configure-diet-button'
                    href={
                      (getPathname({
                        href: {
                          pathname: '/configure-diet/[slug]',
                          params: { slug: diet.slug },
                        },
                        locale,
                      }) + objectToQueryString(configParams)) as LocalizedRoute
                    }
                    className={cn(
                      buttonVariants({ variant: 'secondary' }),
                      'h-12 min-h-0 w-full rounded-lg px-0 font-medium md:h-10 md:px-5 xl:h-12',
                    )}
                    prefetch={true}
                  >
                    {t('diets.order_diet')}
                  </Link>
                </div>
              )}
              {customActions && (
                <div className='hidden flex-1 gap-4 md:flex'>
                  {customActions}
                </div>
              )}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
