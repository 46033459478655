import { UnderlineAnimation } from '../common/underline-animation';
import { Link } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { IconCircle } from '@tabler/icons-react';
import { type HTMLAttributeAnchorTarget } from 'react';

type ListingItemHeaderProps = {
  componentPrimary?: React.ReactNode;
  urlPrimary?: string;
  urlSecondary?: string;
  urlSecondaryTarget?: HTMLAttributeAnchorTarget;
  namePrimary?: string;
  nameSecondary?: string;
  className?: string;
};

export const ListingItemHeader = ({
  urlPrimary,
  urlSecondary,
  urlSecondaryTarget,
  namePrimary,
  nameSecondary,
  componentPrimary,
  className,
}: ListingItemHeaderProps) => {
  return (
    <header className={cn('w-full', className)}>
      <h3
        className='text-xl font-medium md:mb-0.5 md:text-2xl'
        data-pw='diet-header'
      >
        {componentPrimary && componentPrimary}
        {urlPrimary && (
          <Link
            href={urlPrimary}
            className='group relative mr-2 items-center text-left transition duration-100'
          >
            {namePrimary}
            <UnderlineAnimation />
          </Link>
        )}

        {(urlPrimary || componentPrimary) && urlSecondary && (
          <IconCircle
            stroke={1.25}
            className='mr-2 inline-block h-1.5 w-1.5 rounded-full border border-black'
          />
        )}

        {urlSecondary && (
          <Link
            href={urlSecondary}
            className='group relative items-center transition duration-100'
            target={urlSecondaryTarget}
          >
            {nameSecondary}
            <UnderlineAnimation />
          </Link>
        )}
      </h3>
    </header>
  );
};
