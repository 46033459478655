'use client';

import { type CartStore, createCartStore } from '../store/cart-store';
import { createContext, useContext, useRef } from 'react';
import { type StoreApi, useStore } from 'zustand';

const CartStoreContext = createContext<StoreApi<CartStore> | null>(null);

type Props = {
  children: React.ReactNode[];
  storageKey?: string;
};

export const CartProvider = ({ children, storageKey = 'cart' }: Props) => {
  const storeRef = useRef<StoreApi<CartStore>>(undefined);
  if (!storeRef.current) {
    storeRef.current = createCartStore({ storageKey });
  }

  return (
    <CartStoreContext.Provider value={storeRef.current}>
      {children}
    </CartStoreContext.Provider>
  );
};

export const useCartStore = <T,>(selector: (store: CartStore) => T): T => {
  const cartStoreContext = useContext(CartStoreContext);

  if (!cartStoreContext) {
    throw new Error(`useCartStore must be use within CartProvider`);
  }

  return useStore(cartStoreContext, selector);
};
