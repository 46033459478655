'use client';

import { useItemPrice } from '../../hooks/use-item-price';
import { type ItemPriceBody } from '../../types/item-price-body.type';
import { useCartStore } from '../cart-provider';
import { ConfigurationBar } from './configuration-bar';
import { ConfigurationPricing } from './configuration-pricing';
import { ConfigureDietForm } from './configure-diet-form';
import { SectionHero } from '@/components/common/section-hero';
import { Form } from '@/components/ui/form';
import { toast } from '@/components/ui/sonner';
import { sendEvent } from '@/features/analytics/gtm/event';
import { useConfigureDietForm } from '@/features/cart/forms/configure-diet';
import { type Cart, type ConfigurationItem } from '@/features/cart/types';
import { type DietCatalogDetail } from '@/features/diets/types';
import { type DietSearchResultsItemType } from '@/features/diets/types/diet-search-resilts.type';
import { type FiltersSetup } from '@/features/filters/types/filters-setup.type';
import { Globals } from '@/features/globals/types/globals.type';
import { format } from 'date-fns';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { type RefObject, useEffect, useRef, useTransition } from 'react';

const getDietDefaultVariant = (diet: DietCatalogDetail) => {
  const baseVariantId = diet.base.variant;
  let mealTypesIds: string[] = [];
  let calories: number = 0;

  // in case if diet variant exists
  if (baseVariantId) {
    const baseVariant = diet.variants?.find(
      (variant) => variant.id === baseVariantId,
    );

    if (!baseVariant) return null;

    mealTypesIds = baseVariant.mealTypes.map((mealType) => mealType.id);
    calories = diet.base.caloricity;
  } else {
    // in case if diet supervariant exists
    const calorifics = diet.calorifics.find(
      (cal) => cal.calories === diet.base.caloricity,
    );

    const lowestCalories = calorifics?.mealsCaloricValue.reduce(
      (min, current) => (current.caloricity < min.caloricity ? current : min),
    );

    mealTypesIds = lowestCalories ? [lowestCalories.mealId] : [];
    calories = lowestCalories?.caloricity || 0;
  }

  return {
    merchantMealsIds: mealTypesIds,
    calories: calories,
  };
};

export const ConfigureDietPage = ({
  configurationItem,
  configuration,
  diet,
  filtersSetup,
  globals,
}: {
  configurationItem?: ConfigurationItem;
  configuration?: DietSearchResultsItemType | null;
  diet: DietCatalogDetail;
  filtersSetup?: FiltersSetup;
  globals?: Globals | null;
}) => {
  const [isSaving, startTransition] = useTransition();
  const cartStore = useCartStore((state) => state);
  const router = useRouter();
  const t = useTranslations();

  const pricingRef = useRef<HTMLDivElement>(null);

  const variant =
    (!configurationItem && !configuration && getDietDefaultVariant(diet)) ||
    null;

  const form = useConfigureDietForm({
    defaults: configurationItem || {
      mealIds:
        configuration?.merchantMealsIds ?? variant?.merchantMealsIds ?? [],
      calories: configuration?.calories ?? diet.base.caloricity ?? 0,
      dates: undefined,
      sourceCalorifics: configuration?.sourceCalorifics,
    },

    diet,
    min: diet.catering.days.min,
    max: diet.catering.days.max,
    filtersSetup: filtersSetup,
    minMeals: diet.minMealTypes,
  });

  const { isSubmitSuccessful, isSubmitted, isSubmitting, errors } =
    form.formState;

  const handleSave = async () => {
    const item = configurationItem ? { id: configurationItem.id } : {};
    const url = item.id ? '/api/cart/update' : '/api/cart/add';

    const body: ItemPriceBody = {
      calories: +form.getValues('kcal'),
      mealIds: form.getValues('meals'),
      dates: form
        .getValues('dates')
        .map((date: Date) => format(date, 'yyyy-MM-dd')),
      dietId: diet.id,
      merchantId: diet.catering.id,
    };

    startTransition(async () => {
      if (item.id) {
        cartStore.addPendingEditItem(body, item.id);
      } else {
        cartStore.addPendingItem(body);
      }
      router.push('/cart');

      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          cartId: cartStore.cartId,
          item: {
            ...configurationItem,
            ...item,
            ...body,
            parameters: {
              ecoContainers: false,
            },
          },
        }),
      })
        .then(async (res: Response) => {
          if (!res.ok) {
            throw new Error('Failed to add to cart');
          }

          const response = res.json() as Promise<{
            cartId: string;
            cart: Cart;
            itemId?: string; // only for add
          }>;

          const { cartId } = await response;

          if (data && diet) {
            sendEvent({
              event: 'add_to_cart',
              ecommerce: {
                cartId: cartId,
                currency: 'PLN',
                value: data?.pricing.final.gross,
                items: [
                  {
                    item_id: diet.id,
                    item_name: diet.name,
                    item_brand: diet.catering.name,
                  },
                ],
              },
            });
          }

          return response;
        })
        .then(({ cartId, cart, itemId }) => {
          cartStore.updateCart(cartId, cart, 'update', t);
          if (!item.id && itemId) {
            cartStore.addDeliveryCost(
              itemId,
              diet.id,
              diet.catering.deliveryCosts,
            );
          }
        })
        .catch((error: Error) => toast.error(error.message));
    });
  };

  const { data, isPending, isFetching } = useItemPrice({
    dietId: diet.id,
    merchantId: diet.catering.id,
    form,
  });

  useEffect(() => {
    if (isSubmitted && !isSubmitSuccessful) {
      if (typeof document !== 'undefined') {
        if (errors.meals) {
          document
            .getElementById('cart-meals')
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (errors.dates) {
          document
            .getElementById('cart-calendar')
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [isSubmitSuccessful, isSubmitted, isSubmitting]);

  return (
    <Form {...form}>
      <form
        id='configure-diet-form'
        data-pw='configure-diet-form'
        onSubmit={form.handleSubmit(handleSave)}
        className='md:grid gap-2 lg:grid-cols-4 lg:gap-10'
      >
        <div className='lg:col-span-3'>
          <SectionHero>
            <ConfigureDietForm form={form} diet={diet} globals={globals} />
          </SectionHero>
        </div>
        <div
          className='sticky top-28 self-start max-md:container'
          ref={pricingRef}
        >
          <ConfigurationPricing
            diet={diet}
            form={form}
            pricing={data?.pricing}
            loading={isPending || isFetching}
            pending={isSaving}
            edit={!!configurationItem}
          />
        </div>
        <ConfigurationBar
          days={form.watch('dates')?.length}
          loading={isPending || isFetching}
          pricingRef={pricingRef as RefObject<HTMLDivElement>}
          pricing={data?.pricing}
          edit={!!configurationItem}
          disabled={isSaving}
          costs={diet.catering.deliveryCosts}
        />
      </form>
    </Form>
  );
};
