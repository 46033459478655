'use client';

import { sendEvent } from '../../gtm/event';
import { type ConfigureDietFormType } from '@/features/cart/forms/configure-diet';
import { type DietCatalogDetail } from '@/features/diets/types';
import { max, min } from 'date-fns';
import { useEffect, useState } from 'react';

export const EventViewItem = ({
  item,
  configuration,
  price,
  end,
  isDirty,
  loading,
}: {
  item: DietCatalogDetail;
  configuration: Pick<ConfigureDietFormType, 'dates' | 'kcal'>;
  price?: number;
  end?: boolean;
  isDirty?: boolean;
  loading?: boolean;
}) => {
  const [priceHolder, setPriceHolder] = useState<number | undefined>(undefined);
  const [datesHolder, setDatesHolder] = useState<Date[]>(
    configuration.dates || [],
  );
  const configurationTimes = configuration.dates?.map((date) => date.getTime());
  const holderTimes = datesHolder?.map((date) => date.getTime());

  const firstDate = min(configuration.dates || []);
  const lastDate = max(configuration.dates || []);

  useEffect(() => {
    if (price) {
      if (price > 0) {
        if (priceHolder !== price) {
          setPriceHolder(price);
        }
      }
    }
  }, [price]);

  // compare if the dates are the same
  useEffect(() => {
    const intersection = configurationTimes
      ?.filter((time) => !holderTimes?.includes(time))
      .concat(
        holderTimes?.filter((date) => !configurationTimes?.includes(date)),
      );
    if (intersection?.length > 0) {
      setDatesHolder(configuration.dates);
    }
  }, [configuration.dates]);

  useEffect(() => {
    if (!loading && isDirty) {
      sendEvent({
        event: end ? 'CUSTOMIZE_DIET_END' : 'view_item',
        ecommerce: {
          currency: 'PLN',
          value: priceHolder || 0,
          items: [
            {
              item_id: item.id,
              item_name: item.name,
              item_brand: item.catering.name,
              item_category: item.tags.map((tag) => tag.name).join(', '),
              item_category2: configuration.kcal,
              item_category3: firstDate.getTime(),
              item_category4: lastDate.getTime(),
            },
          ],
        },
      });
    }
  }, [configuration.kcal, datesHolder, priceHolder]);

  return null;
};
