import { decryptData, encryptData } from './crypto';

export const setEncryptedLocalStorage = <T>(key: string, data: T): void => {
  const encryptedData = encryptData(data);
  localStorage.setItem(key, encryptedData);
};

export const getDecryptedLocalStorage = <T>(key: string): T | null => {
  if (typeof window === 'undefined') return null;
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    return decryptData(encryptedData);
  }
  return null;
};
