'use client';

import { type ConfigureDietReturnFormType } from '../../forms/configure-diet';
import { FormSection } from './configure-form-section';
import { FormButtonSelect } from '@/components/form/form-button-select';
import {
  type DietCatalogCalorificsType,
  type DietCatalogDetail,
} from '@/features/diets/types';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

export const ConfigureVariants = ({
  form,
  diet,
}: {
  form: ConfigureDietReturnFormType;
  diet: DietCatalogDetail;
}) => {
  const t = useTranslations();
  const variant = form.watch('variant');
  const [calorifics, setCalorifics] = useState<DietCatalogCalorificsType[]>([]);

  useEffect(() => {
    if (!diet.variants) return;

    const currentVariant = diet.variants.find((item) => item.id === variant);
    if (!currentVariant) return;

    // set available calorifics
    setCalorifics(currentVariant.calorifics || []);
  }, [variant]);

  const handleChangeMeals = (variantId: string) => {
    if (!variantId) return;

    const newVariant = diet.variants?.find((item) => item.id === variantId);
    form.setValue(
      'meals',
      newVariant?.mealTypes.map((mealType) => mealType.id) || [''],
    );

    const smallestCalorific = newVariant?.calorifics
      ?.sort((a, b) => a.calories - b.calories)[0]
      ?.calories.toString();

    form.setValue('kcal', smallestCalorific || '', {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  if (!diet.variants) return null;
  return (
    <div className='space-y-8'>
      <FormSection label={t('cart.configuration.caloricity')}>
        <div className='flex w-full flex-col items-start gap-2 md:flex-row md:items-center'>
          <FormButtonSelect
            fieldName='kcal'
            form={form}
            fieldOptions={calorifics
              .sort((a, b) => a.calories - b.calories)
              .map((item) => ({
                label: (
                  <span className='flex w-full items-center justify-between md:block'>
                    <span>{t('common.kcal', { kcal: item.calories })}</span>
                    <span className='space-x-1 md:hidden'>
                      <span>{t('cart.configuration.priceLowerCase')}:</span>
                      <span className='font-bold'>
                        {item.price.toFixed(2)} zł
                      </span>
                      <span>{t('cart.configuration.perDay')}</span>
                    </span>
                  </span>
                ),
                value: item.calories.toString(),
              }))}
          />
        </div>
      </FormSection>

      <FormSection label={t('cart.configuration.variants')}>
        <div className='flex w-full space-x-2'>
          <FormButtonSelect
            fieldName='variant'
            form={form}
            layout='grid'
            fieldOptions={diet.variants
              .sort((a, b) => a.position - b.position)
              .map((item) => ({
                label: item.name,
                value: item.id,
                description: item.mealTypes
                  .sort((a, b) => a.position - b.position)
                  .map((meal) => meal.name)
                  .join(', '),
              }))}
            actionAfterChange={handleChangeMeals}
          />
        </div>
      </FormSection>
    </div>
  );
};
